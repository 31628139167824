<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerTitleTemplate>
        <span *ngIf="event.Name" class="event-detail-title">
            <h4 title="{{ event.Name }}">{{ getEventDisplayName(event) }}</h4>
            <div *ngIf="eventId" appIfUserFeature="EventAdmin">
                <button type="button" (click)="edit()">Edit</button>
            </div>
        </span>
    </ng-template>

    <ng-template #bannerSubTitleTemplate>
        <div class="flex-hbox flex-gap" style="max-width: 300px">
            <div style="max-width: 300px">
                <div><span style="font-weight: bold;">Location:</span> {{ event.Location }}</div>
                <div><span style="font-weight: bold;">Dates:</span> {{ getEventDateRange() }}</div>
                <div *ngIf="hasWebcastIdOnAnyMeeting()"><span style="font-weight: bold;">Last Updated: </span> {{ getLastUpdate() }}</div>
            </div>
        </div>
    </ng-template>

    <ng-template #bannerFormTemplate>
        <div class="flex-hbox" style="text-align: left; margin-left: 30px;">

            <div class="flex-2">
                <div><span style="font-weight: bold;">Event Type:</span> {{ eventType && eventType.Name }}</div>
                <div><span style="font-weight: bold;">Sub Category:</span> {{ event.SubCategory }}</div>
                <div><span style="font-weight: bold;">Presenter Type:</span> {{ eventPresenterType && eventPresenterType.Name}}</div>
            </div>

            <div class="flex-2">
                <div><span style="font-weight: bold;">Max Attendance:</span> {{ event.MaxAttendance }}</div>
                <div>
                    <span><span style="font-weight: bold;">Reg Link:&nbsp;</span></span>
                    <span [style.display]="(event.IERRegistrationLink?.trim()?.length > 0) ? 'inline' : 'none'"><a target="_blank" href="{{ getAbsoluteUrl(event.IERRegistrationLink) }}">IER Reg</a></span>
                    <span appIfUserFeature="EventAdmin" *ngIf="(event.GIBRegistrationLink?.trim()?.length > 0)">&nbsp;|&nbsp;</span>
                    <span appIfUserFeature="EventAdmin" *ngIf="(event.GIBRegistrationLink?.trim()?.length > 0)"><a target="_blank" href="{{ getAbsoluteUrl(event.GIBRegistrationLink) }}">GIB Reg</a></span>
                </div>
                <div>
                    <span><span style="font-weight: bold;">Agenda:&nbsp;</span></span>
                    <span *ngIf="(event.AgendaLink?.trim()?.length > 0)"><a target="_blank" href="{{ getAbsoluteUrl(event.AgendaLink) }}">Agenda</a></span>
                </div>
            </div>

            <div class="flex-2">
                <div><span style="font-weight: bold;">Event Coordinator:</span> {{ coordinators && getListOfUsersAsDisplayString(coordinators) }}</div>
                <div><span style="font-weight: bold;">Analyst:</span> {{ analysts && getListOfUsersAsDisplayString(analysts)}}</div>
                <div><span style="font-weight: bold;">Last Invite Sent:</span> {{ getFormattedDate(event.LastInviteSent) }}</div>
            </div>

            <div class="flex-3">
                <div class="event-detail-notes" title="{{ event.Notes }}"><span style="font-weight: bold;">Notes:</span> {{ event.Notes }}</div>
                <div appIfUserFeature="EventAdmin" class="event-detail-notes" title="{{ event.PrivateNotes }}"><span style="font-weight: bold;">Internal Notes:</span> {{ event.PrivateNotes }}</div>
            </div>

            <div class="form-group" >
                <div class="btn-group" dropdown>
                    <button id="button-basic"
                            dropdownToggle type="button"
                            class="btn btn-sm btn-success dropdown-toggle"
                            aria-controls="dropdown-basic"
                            appIfUserFeature="MailMerge">
                        <span class="fa fa-envelope"></span> <span class="caret" style="margin-left: 2px"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                        role="menu" aria-labelledby="button-basic">
                        <li role="menuitem">
                            <a class="dropdown-item" (click)="onSendInviteClick(false)" >Send Invite</a>
                        </li>
                        <li role="menuitem">
                            <a class="dropdown-item" (click)="onSendInviteClick(true)" appIfUserFeature="EventAdmin">Send Invite with Query</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </ng-template>

    <app-page-banner
            faicon="fa-calendar-o"
            faiconcolor="#ff8080"
            [bannerFormTemplate]="bannerFormTemplate"
            [titleTemplate]="bannerTitleTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate"
    >
    </app-page-banner>

    <div class="page-body flex flex-hbox flex-gap">

        <div class="flex-2 flex-vbox flex-gap">
            <div class="flex" *ngIf="userIsEventAdmin() || eventType.Id== 2">
                <tabset class="flex-tabset" style="height: 100%;">
                    <tab heading="Companies">
                        <app-tab-company-list
                                class="flex flex-vbox"
                                [eventId]="eventId"
                                [companyDataUpdated]="companyDataUpdated"
                                [meetingDataUpdated]="meetingDataUpdated"
                                [readOnly]="!userIsEventAdmin()"
                        >
                        </app-tab-company-list>
                    </tab>
                    <tab heading="Details" *ngIf="userIsEventAdmin()" >
                        <div class="flex flex-vbox event-details-container" style="padding: 5px; background-color: white; border: 1px solid #ccc; overflow-y: scroll;">
                            <div class="flex-hbox">
                                <div class="flex">
                                    <label>Start Date</label>
                                    <div>{{ getFormattedDate(event.BeginDate) }}</div>
                                </div>
                                <div class="flex">
                                    <label>End Date</label>
                                    <div>{{ getFormattedDate(event.EndDate) }}</div>
                                </div>
                            </div>

                            <hr style="margin-top: 5px; margin-bottom: 5px;" />

                            <div class="flex-hbox">
                                <div class="flex">
                                    <label>Event Type</label>
                                    <div>{{ eventType && eventType.Name }}</div>
                                </div>
                                <div class="flex">
                                    <label>Presenter Type</label>
                                    <div>{{ eventPresenterType && eventPresenterType.Name }}</div>
                                </div>
                            </div>

                            <hr style="margin-top: 5px; margin-bottom: 5px;" />

                            <div class="flex-hbox">
                                <div class="flex">
                                    <label>Sub Category</label>
                                    <div>{{ event.SubCategory }}</div>
                                </div>
                                <div class="flex">
                                    <label>Conference</label>
                                    <div>{{ eventConference && eventConference.Name }}</div>
                                </div>
                            </div>
                            <hr style="margin-top: 5px; margin-bottom: 5px;" />
                            <div class="flex-hbox">
                                <div class="flex">
                                    <label>Event Coordinator</label>
                                    <div>{{ event.Coordinator }}</div>
                                </div>
                                <div class="flex">
                                    <label>Location</label>
                                    <div>{{ event.Location }}</div>
                                </div>
                            </div>
                            <hr style="margin-top: 5px; margin-bottom: 5px;" />
                            <div class="flex-hbox">
                                <div class="flex">
                                    <label>Public</label>
                                    <div>{{ (event.IsPublic ? "Yes" : "No") }}</div>
                                </div>
                                <div class="flex">
                                    <label>Cost</label>
                                    <div>${{ event.Cost }}</div>
                                </div>
                            </div>
                            <hr style="margin-top: 5px; margin-bottom: 5px;" />
                            <div class="flex-hbox">
                                <div class="flex flex-2">
                                    <label>Canceled</label>
                                    <div>{{ (!event.IsActive ? "Yes" : "") }}</div>
                                </div>
                            </div>
                            <hr style="margin-top: 5px; margin-bottom: 5px;" />
                            <div class="flex-hbox">
                                <div class="flex">
                                    <label>Notes</label>
                                    <div style="word-break: break-word; margin-right: 5px;">{{ event.Notes }}</div>
                                </div>
                                <div class="flex">
                                    <label>Internal Notes</label>
                                    <div style="word-break: break-word;">{{ event.PrivateNotes }}</div>
                                </div>
                            </div>
                            <hr style="margin-top: 5px; margin-bottom: 5px;" />
                            <div class="flex-hbox">
                                <div class="flex">
                                    <label>IER Reg Link</label>
                                    <div>
                                        <a target="_blank" href="{{ getAbsoluteUrl(event.IERRegistrationLink) }}" style="word-break: break-word;">{{ event.IERRegistrationLink?.trim() }}</a>
                                    </div>
                                </div>
                                <div class="flex">
                                    <label>GIB Reg Link</label>
                                    <div>
                                        <a target="_blank" href="{{ getAbsoluteUrl(event.GIBRegistrationLink) }}" style="word-break: break-word;">{{ event.GIBRegistrationLink?.trim() }}</a>
                                    </div>
                                </div>
                            </div>

                            <hr style="margin-top: 5px; margin-bottom: 5px;" />
                            <div class="flex-hbox">
                                <div class="flex">
                                    <label>Max Attendance</label>
                                    <div style="word-break: break-word;">{{ event.MaxAttendance }}</div>
                                </div>
                                <div class="flex">
                                    <label>Dry Run Scheduled</label>
                                    <div style="word-break: break-word;">{{ event.DryRunScheduled }}</div>
                                </div>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </div>
            <div class="flex">
                <tabset class="flex-tabset" style="height: 100%;">
                    <tab heading="Meetings">
                        <app-tab-meeting-list
                                class="flex flex-vbox"
                                [eventId]="eventId"
                                [attendeeDataUpdated]="attendeeDataUpdated"
                                [webcastAttendeeDataUpdated]="webcastAttendeeDataUpdated"
                                [meetingDataUpdated]="meetingDataUpdated"
                                [companyDataUpdated]="companyDataUpdated"
                        >
                        </app-tab-meeting-list>
                    </tab>
                </tabset>
            </div>
        </div>
        <div class="flex-3 flex-vbox">
            <form class="flex">
                <tabset class="flex-tabset" style="height: 100%;">
                    <tab heading="Attendees">
                        <app-tab-attendee-list
                                class="flex flex-vbox"
                                [eventId]="eventId"
                                [attendeeDataUpdated]="attendeeDataUpdated"
                                [meetingDataUpdated]="meetingDataUpdated"
                        >
                        </app-tab-attendee-list>
                    </tab>
                    <tab
                            heading="Webcast Attendees"
                            *ngIf="userIsEventAdmin()"
                    >
                        <app-tab-webcast-attendee-list
                                class="flex flex-vbox"
                                [eventId]="eventId"
                                [attendeeDataUpdated]="attendeeDataUpdated"
                                [webcastAttendeeDataUpdated]="webcastAttendeeDataUpdated"
                        >
                        </app-tab-webcast-attendee-list>
                    </tab>
                    <tab
                        heading="Invites"
                        *ngIf="true">
                        <app-tab-invite-list
                                class="flex flex-vbox"
                                [eventId]="eventId"
                                [attendeeDataUpdated]="attendeeDataUpdated"
                        >
                        </app-tab-invite-list>
                    </tab>
                </tabset>
            </form>
        </div>
    </div>
</div>
