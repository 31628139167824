import {Component, EventEmitter, Input, OnInit, SimpleChange, SimpleChanges} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {CellClickedEvent, ColDef, ICellRendererParams} from "ag-grid-community";
import {EventFormComponent} from "../EventForm/event-form.component";
import {combineLatest} from "rxjs";
import {EventService} from "../../Shared/Services/event.service";
import {debounceTime, map, startWith, switchMap} from "rxjs/operators";
import {CompanyPresenterListRenderer} from "../../Widget/CellRenderers/company-presenter-list-renderer.component";
import {CalendarEventAccount} from "../../../Components/Events/CalendarEventAccount";
import {yesNoCellRenderer} from "../../Shared/ag-grid-cell-renderers";
import {ValueGetterParams} from "ag-grid-community/dist/lib/entities/colDef";
import {ExcelUploadComponent} from "../../Widget/DataUpload/excel-upload.component";
import {CompanyTickerListRenderer} from "../../Widget/CellRenderers/company-ticker-list-renderer.component";
import {BaseHttpService} from "../../Shared/Services/base-http.service";

@Component({
    selector: "app-tab-company-list",
    template: `
        <div class="flex-1 flex-vbox">
            <div style="margin-bottom: 5px;">
                <form [formGroup]="companySearchForm">
                    <div class="flex-hbox flex-gap">
                        <button type="button"
                                class="btn btn-sm btn-default"
                                appIfUserFeature="EventAdmin"
                                (click)="addAttendee()">
                            <span class="fa fa-plus"></span></button>
                        <button type="button"
                                class="btn btn-sm btn-default"
                                appIfUserFeature="EventAdmin"
                                appIfUserFeature="EventCompanyUpload"
                                (click)="openUploadModal()">
                            <span class="fa fa-upload"></span>
                        </button>
                        <input formControlName="searchName"
                               type="search"
                               placeholder="Search Name"
                               class="flex-4 form-control input-sm"/>
                        <input formControlName="searchTicker"
                               type="search"
                               placeholder="Search Tickers"
                               class="flex-2 form-control input-sm"/>
                        <app-event-company-status-picker
                                formControlName="filterStatus"
                                placeHolder="Status(es)"
                                [alignRight]="true"></app-event-company-status-picker>
                        <button type="button"
                                class="btn btn-sm btn-default"
                                (click)="downloadModal()">
                            <a class="fa fa-download" style="color: black; text-decoration: none"></a>
                        </button>
                    </div>
                </form>
            </div>

            <ag-grid-angular
                    style="width: 100%; height: 100%;"
                    class="ag-theme-balham"
                    [gridOptions]="gridOptions"
                    [rowData]="rowData$ | async"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    (gridReady)="onGridReady($event)"
                    (cellClicked)="onCellClick($event)"
            >
            </ag-grid-angular>
        </div>`
})
export class TabCompanyListComponent extends BaseGridComponent<CalendarEventAccount> implements OnInit {

    @Input()
    eventId: number;

    @Input()
    companyDataUpdated = new EventEmitter<boolean>();

    @Input()
    meetingDataUpdated = new EventEmitter<boolean>();

    @Input()
    readOnly : boolean = false;

    companySearchForm: UntypedFormGroup = this.fb.group({
        searchName: this.fb.control(''),
        searchTicker: this.fb.control(''),
        filterStatus: this.fb.control([]),
    });

    columnDefs: ColDef[] = [
        {field: 'Name', tooltipField: 'Name', flex: 1, sort: 'asc'},
        {
            field: 'Ticker',
            width: 65,
            tooltipField: 'Ticker',
            cellRenderer: CompanyTickerListRenderer,
            cellRendererParams: (params: ICellRendererParams<CalendarEventAccount>) => {
                return {
                    HeaderName: params.data.Ticker,
                    HasNotes: !!params.data.Notes && params.data.Notes?.length > 0,
                    Placement: 'right'
                }
            },
        },
        {field: 'Status', width: 80, tooltipField: 'Status'},
        {field: 'InvitedBy', headerName: 'Invited By', width: 90, tooltipField: 'InvitedBy', hide: this.readOnly },
        {
            field: 'EventPresenters',
            headerName: '#Pres',
            headerTooltip: '# of Presenters',
            maxWidth: 80,
            flex: 1,
            cellRenderer: CompanyPresenterListRenderer,
            cellRendererParams: (params: ICellRendererParams<CalendarEventAccount>) => {
                return {
                    HeaderName: params.data.Name,
                    ShowCompanyName: false,
                    Placement: 'left'
                }
            },
            type: 'numericColumn',
        },
        {
            field: 'Meetings',
            headerName: '#Mtgs',
            headerTooltip: "# of Meetings",
            width: 80,
            valueGetter: (params: ValueGetterParams<CalendarEventAccount>) => params.data.Meetings.length,
            type: 'numericColumn',
        },
    ];

    constructor(private fb: UntypedFormBuilder,
                private modalService: BsModalService,
                private eventService: EventService,
                ) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.overlayNoRowsTemplate = `<span>No Companies</span>`;
        this.loadCompanyData();
    }

    ngOnChanges(changes: SimpleChanges): void {

        if(changes.readOnly){
            this.columnDefs.find(c => c.field === "InvitedBy").hide = this.readOnly
            this.gridApi?.setColumnDefs(this.columnDefs)
        }
    }


    onCellClick($event: CellClickedEvent<CalendarEventAccount>) {
        if ($event.column.getColId() !== 'EventPresenters' && $event.column.getColId() !== 'Ticker' && this.readOnly !== true) {
            this.openCompanyForm(this.eventId, $event.data.Name, true);
        }
    }

    addAttendee() {
        this.openCompanyForm(this.eventId, '', false);
    }

    openCompanyForm(eventId: number, companyFilter: string, companySelectedFilter: boolean): void {
        const initialState = {
            eventId: eventId,
            isCompaniesTabFocused: true,
            companyFilter: companyFilter,
            companySelectedFilter: companySelectedFilter,
        };

        let modalFormRef = this.modalService.show(EventFormComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: 'static',
            class: 'modal-xl',
        });

        modalFormRef.content.dataUpdated
            .subscribe(_ => {
                this.loadCompanyData();
                this.companyDataUpdated.emit(true);
            });
    }

    private loadCompanyData() {
        let searchForm$ = this.companySearchForm.valueChanges.pipe(
            startWith(this.companySearchForm.getRawValue()),
            debounceTime(300));

        let meetingOrAttendeesOrEventUpdated$ = combineLatest(
            this.meetingDataUpdated.pipe(startWith(true)),
            this.companyDataUpdated.pipe(startWith(true)),
        );

        let companies$ = meetingOrAttendeesOrEventUpdated$.pipe(
            switchMap(() => this.eventService.getEventAccounts(this.eventId))
        );

        this.rowData$ = combineLatest([searchForm$, companies$]).pipe(
            map(([searchForm, companies]) => {

                let searchTerms = searchForm.searchName.split(/[\s,]+/);

                let tickers: string[] = searchForm.searchTicker
                    .replace(/\s/g, ",")
                    .split(",")
                    .filter(t => t !== '')
                    .map(t => t.toUpperCase());

                return companies
                    .filter(a => tickers.length === 0 || tickers.includes(a.Ticker))
                    .filter(a => searchForm.filterStatus.length === 0 || searchForm.filterStatus.includes(a.StatusId))
                    .filter(a => searchTerms.every(term =>
                        a.Name && a.Name.toLowerCase().indexOf(term.toLowerCase()) > -1
                    ));
            }));
    }

    openUploadModal() {
        this.openExcelModal({
            fileTitle: "Event Company List",
            fileTemplatePath: `/event/id/${this.eventId}/accounts/template`,
            fileUploadPath: `/event/id/${this.eventId}/accounts/upload`,
        })
    }

    downloadModal() {
        this.eventService.getEventCompanyDownload(this.eventId).subscribe();
    }

    openExcelModal(initialState: any): void {
        let modalRef = this.modalService.show(ExcelUploadComponent,
            { initialState: initialState, ignoreBackdropClick: true, keyboard: false, animated: false });

        modalRef.content.dataUpdated.subscribe(() => {
            this.loadCompanyData();
        });
    }
}
