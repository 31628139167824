import {Routes} from "@angular/router";
import {SharedEmailsComponent} from "./SharedEmails/shared-emails.component";
import {ReadershipComponent} from "./Readership/readership.component";
import {ComposeEmailComponent} from "./ComposeEmail/compose-email.component";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {UserFeatureName} from "../Admin/UserFeatures/user-features.service";
import {MsalGuard} from "@azure/msal-angular";
import {EmailRoutePaths} from "./email-route-paths";
import {ComposeEmailCompareComponent} from "./ComposeEmailCompare/compose-email-compare.component";

export const emailRoutes: Routes = [
  {
    path: EmailRoutePaths.BasePath,
    canActivate: [UserCanActivateRoute, MsalGuard],
    canActivateChild: [MsalGuard],
    children: [
      {
        path: EmailRoutePaths.SharedEmailsPathFragment,
        component: SharedEmailsComponent,
        canActivate: [UserCanActivateRoute],
        data: {
          title: "Shared Emails",
          requiredFeatures: [UserFeatureName.SharedEmails]
        },
      },
      {
        path: EmailRoutePaths.ReadershipPathFragment,
        component: ReadershipComponent,
        canActivate: [UserCanActivateRoute],
        data: {
          title: "Readership",
        },
      },
      {
        path: EmailRoutePaths.ComposeEmailPathFragment,
        component: ComposeEmailComponent,
        canActivate: [UserCanActivateRoute],
        data: {
          title: "Compose Email",
          requiredFeatures: [UserFeatureName.MailMerge]
        },
      },
      {
        path: EmailRoutePaths.ComposeEmailComparePathFragment,
        component: ComposeEmailCompareComponent,
        canActivate: [UserCanActivateRoute],
        data: {
          title: "Compose Email Compare",
          requiredFeatures: [UserFeatureName.MailMerge]
        },
      },
    ],
  },
];
