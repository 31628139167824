export class EmailRoutePaths {
  static readonly BasePath: string = "email";

  static readonly SharedEmailsPathFragment: string = "shared";
  static readonly ReadershipPathFragment: string = "readership";
  static readonly ComposeEmailPathFragment: string = "compose";
  static readonly ComposeEmailComparePathFragment: string = "compare";

  static readonly SharedEmails: string = `${EmailRoutePaths.BasePath}/${EmailRoutePaths.SharedEmailsPathFragment}`;
  static readonly Readership: string = `${EmailRoutePaths.BasePath}/${EmailRoutePaths.ReadershipPathFragment}`;
  static readonly ComposeEmail: string = `${EmailRoutePaths.BasePath}/${EmailRoutePaths.ComposeEmailPathFragment}`;
}
