import {Component, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {User} from "../../../Models/User";
import {UserService} from "../../Shared/Services/user.service";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {CalendarEvent} from "../../../Components/Events/CalendarEvent";
import {EventService} from "../../Shared/Services/event.service";
import * as moment from "moment";
import {getNoRowsOverlayTemplate} from "../../Shared/ag-grid-options";
import {DateRange} from "../../Widget/DateRangePicker/date-range";
import * as _ from "lodash";
import {debounceTime, map, startWith, switchMap, tap} from "rxjs/operators";
import {CacheService} from "../../Shared/Services/cache.service";
import {Observable, of} from "rxjs";
import {
    eventAttendanceCountCellRenderer,
    dateRenderer,
} from "../../Shared/ag-grid-cell-renderers";
import {EventFormComponent} from "../EventForm/event-form.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {ColDef} from "ag-grid-community";
import {nullableValueComparator} from "../../Shared/ag-grid-cell-comparators";
import { getEventDisplayName } from "../../../Helpers/DisplayStringHelper";
import {Router} from "@angular/router";
import {EventRoutePaths} from "../event-route-paths";
import {UpcomingEventsModal} from "../UpcomingEventsModal/upcoming-events-modal.component";

@Component({
    selector: "app-new-event-list",
    templateUrl: "./event-list.component.html"
})
export class EventListComponent extends BaseGridComponent<CalendarEvent> implements OnInit {

    private allUsers: User[] = [];

    private oldStart: moment.Moment;
    private oldEnd: moment.Moment;
    private eventAdded: boolean = false;
    private calendarEvents: CalendarEvent[] = [];

    columnDefs: ColDef[] = [
        {
            field: 'BeginDate',
            headerName: 'Start Date',
            maxWidth: 108,
            headerTooltip: 'Start Date',
            sort: 'asc',
            flex: 1,
            cellRenderer: dateRenderer,
            tooltipValueGetter: dateRenderer,
            comparator: nullableValueComparator
        },
        {
            field: 'EndDate',
            headerName: 'End Date',
            maxWidth: 85,
            headerTooltip: 'End Date',
            flex: 1,
            cellRenderer: dateRenderer,
            tooltipValueGetter: dateRenderer,
            comparator: nullableValueComparator
        },
        {
            field: 'Name',
            headerName: 'Event Name',
            headerTooltip: 'Event Name',
            flex: 1,
            valueGetter: (params) => getEventDisplayName(params.data),
            tooltipValueGetter: (params) => getEventDisplayName(params.data),
            comparator: nullableValueComparator
        },
        {
            field: 'Type',
            headerName: 'Category',
            maxWidth: 115,
            headerTooltip: 'Category',
            flex: 1,
            tooltipValueGetter: (params) => params.data.Type,
            comparator: nullableValueComparator
        },
        {
            field: 'Location',
            headerName: 'Location/Time',
            maxWidth: 215,
            headerTooltip: 'Location/Time',
            flex: 1,
            valueGetter: (params) => params.data.Location || '',
            tooltipValueGetter: (params) => params.data.Location,
            comparator: nullableValueComparator
        },
        {
            field: 'AnalystUserIds',
            headerName: 'Analyst(s)/Broker(s)',
            maxWidth: 155,
            headerTooltip: 'Analyst(s)/Broker(s)',
            flex: 1,
            valueGetter: _.bind(this.renderAnalystsAndBrokers, this),
            tooltipValueGetter: _.bind(this.renderAnalystsAndBrokers, this),
            comparator: nullableValueComparator
        },
        {
            field: 'TiersSectorsInvited',
            headerName: 'Tiers Invited',
            maxWidth: 106,
            headerTooltip: 'Tiers Invited',
            flex: 1,
            tooltipValueGetter: (params) => params.data.TiersSectorsInvited,
            comparator: nullableValueComparator
        },
        {
            field: 'IERRegistrationLink',
            headerName: 'Register',
            maxWidth: 80,
            headerTooltip: 'Register Now',
            flex: 1,
            cellRenderer: (params) => this.linkCellRenderer(params, 'Register'),
            tooltipValueGetter: (params) => params.data.IERRegistrationLink,
            comparator: nullableValueComparator
        },
        {
            field: 'InviteLink',
            headerName: 'Invitation',
            maxWidth: 85,
            headerTooltip: 'Invitation Link',
            flex: 1,
            cellRenderer: (params) => this.linkCellRenderer(params, 'Invite'),
            tooltipValueGetter: (params) => params.data.InviteLink,
            comparator: nullableValueComparator
        },
        {
            field: 'AgendaLink',
            headerName: 'Agenda',
            maxWidth: 80,
            headerTooltip: 'Agenda Link',
            flex: 1,
            cellRenderer: (params) => this.linkCellRenderer(params, 'Agenda'),
            tooltipValueGetter: (params) => params.data.AgendaLink,
            comparator: nullableValueComparator
        },
        {
            field: 'NumberOfInterested',
            headerName: 'Int',
            maxWidth: 50,
            headerTooltip: 'Interested',
            flex: 1,
            type: 'numericColumn',
            tooltipValueGetter: (params) => params.data.NumberOfInterested,
            comparator: nullableValueComparator
        },
        {
            field: 'NumberOfAttendees',
            headerName: 'Att',
            maxWidth: 50,
            headerTooltip: 'Registered/Attended',
            flex: 1,
            type: 'numericColumn',
            cellRenderer: (params) => eventAttendanceCountCellRenderer(params),
            tooltipValueGetter: (params) => params.data.NumberOfAttendees,
            comparator: nullableValueComparator
        },
        {
            field: 'MaxAttendance',
            headerName: 'Max Att',
            maxWidth: 80,
            headerTooltip: 'Max Attendance',
            type: 'numericColumn',
            flex: 1,
            tooltipValueGetter: (params) => params.data.MaxAttendance,
            comparator: nullableValueComparator
        },
        {
            field: 'CoordinatorUserIds',
            headerName: 'Coordinator',
            maxWidth: 140,
            headerTooltip: 'Coordinator',
            flex: 1,
            valueGetter: _.bind(this.renderCoordinators, this),
            tooltipValueGetter: _.bind(this.renderCoordinators, this),
            comparator: nullableValueComparator
        },
    ];

    rowClassRules = {
        'non-public-event': (params) => !params.data.IsPublic
    };

    eventSearchForm: UntypedFormGroup = this.fb.group({
        eventAdded: this.fb.control(false),
        searchNameAndLocation: this.fb.control(''),
        searchTicker: this.fb.control(''),
        category: this.fb.control([]),
        analyst: this.fb.control([]),
        month: this.fb.control(new Date()),
        calendarDateRange: this.fb.control({ start: moment().subtract(30, 'day').clone(), end: moment().add(90, 'day').clone() } as DateRange),
        listDateRange: this.fb.control({
            start: moment().clone(),
            end: moment().add(5, 'years').clone()
        } as DateRange),
        upcomingEvents: this.fb.control(true),
    });

    constructor(
        private fb: UntypedFormBuilder,
        private userService: UserService,
        private eventService: EventService,
        private modalService: BsModalService,
        private cacheService: CacheService,
        private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {

        this.gridOptions.overlayNoRowsTemplate = getNoRowsOverlayTemplate('Events');
        this.gridOptions.suppressContextMenu = false;

        let savedFilters = this.cacheService.getValue<any>("EventListFilters");

        if (savedFilters) {
            savedFilters.listDateRange.start = moment(savedFilters.listDateRange.start);
            savedFilters.listDateRange.end = moment(savedFilters.listDateRange.end);
            this.eventSearchForm.patchValue(savedFilters);
        }

        this.userService.getUsers()
            .subscribe(users => {
                this.allUsers = users;
                this.setRowData();
            });
    }

    setRowData(): void {
        this.rowData$ = this.eventSearchForm.valueChanges.pipe(
            startWith(this.eventSearchForm.getRawValue()),
            debounceTime(100),
            tap(formValues => {
                this.cacheService.setValue("EventListFilters", formValues)
                this.gridApi?.showLoadingOverlay();
            }),
            switchMap(formValues => this.mapToCalendarEvents(formValues.listDateRange.start, formValues.listDateRange.end)),
            map((events: CalendarEvent[]) => _.filter(events, ev => this.showEvent(ev)))
        );
    }

    mapToCalendarEvents(start: moment.Moment, end: moment.Moment): Observable<CalendarEvent[]> {
        if (this.eventAdded || !this.oldStart || !this.oldEnd || !start.isSame(this.oldStart, 'day') || !end.isSame(this.oldEnd, 'day')) {
            this.eventAdded = false;
            return this.eventService.searchEvents(start, end).pipe(
                tap(evs => {
                    this.calendarEvents = evs;
                    this.oldStart = start;
                    this.oldEnd = end;
                })
            );
        }

        return of(this.calendarEvents);
    }

    showEvent(ev):boolean {
        return this.eventService.showEvent(ev,
            this.eventSearchForm.value.category,
            this.eventSearchForm.value.searchNameAndLocation,
            this.eventSearchForm.value.searchTicker,
            this.eventSearchForm.value.analyst,
            this.eventSearchForm.value.upcomingEvents,
        );
    }

    onRowClicked(event: { data: CalendarEvent }): void {
        if (event.data.Id) {
            this.router.navigate([EventRoutePaths.EventDetail, event.data.Id]);
        } else if (event.data.RequestId) {
            this.router.navigate([EventRoutePaths.RequestDetail], { queryParams: { requestId: event.data.RequestId } });
        }
    }

    getContextMenuItems(params) {
        return [
            {
                name: 'Export All',
                subMenu: [
                    {
                        name: 'CSV Export',
                        action: () => {
                            params.api.exportDataAsCsv();
                        }
                    },
                    {
                        name: 'Excel Export (.xlsx)',
                        action: () => {
                            params.api.exportDataAsExcel();
                        }
                    },

                ]
            }
        ];
    }

    clearFilters() {
        this.eventSearchForm.patchValue({
            searchNameAndLocation: '',
            searchTicker: '',
            category: [],
            analyst: [],
            month: new Date(),
            listDateRange: { start: moment(), end: moment().add(5, 'years').clone() }
        });
    }

    renderAnalystsAndBrokers(params: any): string {
        return _.chain(this.allUsers)
            .filter(u => _.includes(params.data.AnalystUserIds?.concat(params.data.BrokerUserIds || []) || [], u.Id))
            .map(u => u.LastName)
            .uniq()
            .sortBy(ln => ln)
            .join(', ')
            .value();
    }

    renderCoordinators(params: any): string {
        return _.chain(this.allUsers)
            .filter(u => _.includes(params.data.CoordinatorUserIds || [], u.Id))
            .map(u => u.LastName)
            .uniq()
            .sortBy(ln => ln)
            .join(', ')
            .value();
    }

    openUpcomingEventsModal() {
        let modalRef = this.modalService.show(
            UpcomingEventsModal, {
                initialState: { },
                animated: false,
                keyboard: false,
                backdrop: 'static',
                class: 'modal-xl'
            }
        );
    }

    addEvent() {
        this.openEventModal(null);
    }

    openEventModal(eventId: number): void {
        const initialState = {
            eventId: eventId
        };
        let modalRef = this.modalService.show(EventFormComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static', class: 'modal-xl' });

        modalRef.content.dataUpdated.subscribe(() => {
            this.eventAdded = true;
            this.eventSearchForm.patchValue({ eventAdded: !this.eventSearchForm.get('eventAdded').value });
        });
    }

    linkCellRenderer(params: any, placeholder: string): string {
        let url = params && params.value;

        if (!url || url.trim() === '') {
            return '';
        } else if (
            url.toLowerCase().startsWith("https://") ||
            url.toLowerCase().startsWith("http://")
        ) {
            url = url.trim();
        } else {
            url = `//${url.trim()}`;
        }

        return `<a target="_blank" href="${url}" onclick="event.stopPropagation()">${placeholder ?? url}</a>`;
    }
}
