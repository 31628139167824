import {Injectable} from "@angular/core";
import {BaseHttpService, FileAcceptType, HttpOptions} from "./base-http.service";
import {Observable, of} from "rxjs";
import * as moment from "moment";
import {
    CalendarEvent,
    CompanyPerson,
    EventCompany,
    EventConference,
    EventMeeting,
    EventPresenterType,
    EventType,
    RequestEventMeeting,
} from "../../../Components/Events/CalendarEvent";
import * as _ from "lodash";
import {CalendarEventAccount, EventAccountStatus} from "../../../Components/Events/CalendarEventAccount";
import {map, shareReplay} from "rxjs/operators";
import {Query} from "../../../Services/QueryService";
import {EventEmailTemplate} from "../../../Models/EventEmailTemplate";
import {Contact} from "../../../Models/Contact";

@Injectable({
    providedIn: 'root',
})
export class EventService {

    eventStatuses$: Observable<EventAccountStatus[]>;

    constructor(private baseHttp: BaseHttpService) {
    }

    searchEvents(startDate: moment.Moment, endDate:moment.Moment): Observable<CalendarEvent[]>{
        return this.baseHttp.getData(`/event/${startDate.format("YYYY-MM-DD")}/${endDate.format("YYYY-MM-DD")}`);
    }

    getEventMeetingWebcastIds(startDate: moment.Moment, endDate: moment.Moment): Observable<string[]> {
        const params = {
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD")
        };

        return this.baseHttp.getDataWithConfig(`/event/webcastids`, { params: params });
    }

    getUpcomingEventAttendance(query: Query): Observable<UpcomingEventAttendance[]> {
        return this.baseHttp.postData(`/event/upcomingattendance`, query);
    }

    showEvent(
        e: CalendarEvent,
        categories: string[],
        nameLocationSearch: string,
        searchTicker: string,
        analystIds: string[],
        upcomingEvents: boolean = false
    ): boolean {

        let isValid = true;

        if (isValid && nameLocationSearch && nameLocationSearch.length > 0) {
            const searchTerm = nameLocationSearch.toUpperCase();
            isValid = (e.Name && e.Name.toUpperCase().indexOf(searchTerm) > -1 || false) ||
                (e.Location && e.Location.toUpperCase().indexOf(searchTerm) > -1 || false);
        }

        if (isValid && categories && categories.length > 0) {
            isValid = _.includes(categories, e.Type);
        }

        if (isValid && (e.Tickers?.includes("SOLO") && e.Type === "Analyst Visit")) {
            isValid = false;
        }

        if (isValid && searchTicker && searchTicker.length > 0) {

            const searchTickers = searchTicker
                .replace(/\s/g, ",")
                .split(",")
                .map(t => t.toUpperCase());

            const eventTickers = e.Tickers && e.Tickers.map(t => t?.toUpperCase()) || [];

            isValid = eventTickers.some(t => _.includes(searchTickers, t));
        }

        if (isValid && analystIds && analystIds.length > 0) {
            isValid = _.some(e.AnalystUserIds, userid => _.includes(analystIds, userid));
        }

        if (isValid && upcomingEvents) {
            isValid = !(e.Type === "NDR" || e.Type === "Analyst Visit");
        }

        return isValid;
    }

    getEventTypes(forceRefresh: boolean = false): Observable<EventType[]> {
        return this.baseHttp.getCacheOrData(`/eventtypes`, 5 * 60 * 1000, forceRefresh);
    }

    getEventPresenterTypes(forceRefresh: boolean = false): Observable<EventPresenterType[]> {
        return this.baseHttp.getCacheOrData(`/eventpresentertypes`, 5 * 60 * 1000, forceRefresh);
    }

    getEventConferences(forceRefresh: boolean = false): Observable<EventConference[]> {
        return this.baseHttp.getCacheOrData(`/eventconferences`, 5 * 60 * 1000, forceRefresh).pipe(
            map((confs: EventConference[]) => [{Id: 0, Name: '' }, ...confs]),
            map(confs => _.sortBy(confs, c => c.Name))
        );
    }

    getEventCompanies(): Observable<EventCompany[]> {
        return this.baseHttp.getData(`/eventcompanies`);
    }

    getEvent(eventId: number): Observable<CalendarEvent> {
        return this.baseHttp.getData(`/event/id/${eventId}`);
    }

    getRequest(requestId: number): Observable<CalendarEvent> {
        return this.baseHttp.getCacheOrData(`/event/requestid/${requestId}`, 10000);
    }

    getRequestMeetings(requestId: number): Observable<RequestEventMeeting[]>{
        return this.baseHttp.getData(`/event/requestid/${requestId}/meetings`)
    }

    getRequestMeetingWithAttendees(meetingId: number): Observable<RequestEventMeeting>{
        return this.baseHttp.getCacheOrData(`/event/meetingid/${meetingId}`, 1000)
    }

    getCompanyPeople(companyId: number, pids: number[]): Observable<Contact[]> {
        let config = {
            params: {
                pids: _.map(pids, pid => pid.toString())
            }
        };
        return this.baseHttp.getDataWithConfig(`/eventcompany/${companyId}/people`, config);
    }

    getEventAccounts(eventId: number): Observable<CalendarEventAccount[]> {
        return this.baseHttp.getData(`/event/id/${eventId}/accounts`);
    }

    updateEvent(event: CalendarEvent): Observable<CalendarEvent> {
        return this.baseHttp.postData(`/event/id/${event.Id}`, event);
    }

    updateEventAccounts(eventId: number, eventAccounts: CalendarEventAccount[]): Observable<CalendarEventAccount[]> {
        return this.baseHttp.postData(`/event/id/${eventId}/accounts`, eventAccounts);
    }

    deleteEvent(eventId: number): Observable<any> {
        return this.baseHttp.deleteData(`/event/id/${eventId}`);
    }

    getEventStatuses(): Observable<EventAccountStatus[]> {
        if (!this.eventStatuses$) {
            this.eventStatuses$ = this.baseHttp.getData(`/eventaccountstatuses`).pipe(
                shareReplay<EventAccountStatus[]>(1)
            );
        }
        return this.eventStatuses$;
    }

    getEventCompanyDownload(eventId: number): Observable<any> {
        return this.baseHttp.getFileWithApiFileName(`/event/id/${eventId}/accounts/template`, FileAcceptType.Excel);
    }

    getEventMeetings(eventId: number): Observable<EventMeeting[]> {
        return this.baseHttp.getData(`/event/id/${eventId}/eventmeeting`);
    }

    getEventMeetingIds(eventId: number): Observable<number[]> {
        return this.baseHttp.getData(`/event/id/${eventId}/eventmeeting/ids`);
    }

    getEventContactsByEventId (eventId: number): Observable<EventContactInfo[]> {
        return this.baseHttp.getData(`/event/id/${eventId}/contactinfo`)
    }

    getEventMeetingById(meetingId: number): Observable<EventMeeting> {
        return this.baseHttp.getData(`/eventmeeting/${meetingId}`);
    }

    addUpdateEventMeeting(eventMeeting: EventMeeting): Observable<EventMeeting> {
        return this.baseHttp.postData(`/eventmeeting`, eventMeeting);
    }

    deleteEventMeeting(meetingId: number): Observable<any> {
        return this.baseHttp.deleteData(`/eventmeeting/${meetingId}`);
    }

    updateEventAttendees(eventId: number, updateEventAttendanceDto: UpdateEventAttendanceDto): Observable<any> {
        return this.baseHttp.postData(`/event/id/${eventId}/attendees`, updateEventAttendanceDto);
    }

    getEventContactInfo(eventId: number, contactId: number): Observable<EventContactInfo> {
        return this.baseHttp.getData(`/event/id/${eventId}/contactinfo/${contactId}`);
    }

    updateEventContactInfos(eventId: number, eventContactInfos: EventContactInfo[]): Observable<any> {
        return this.baseHttp.postData(`/event/id/${eventId}/contactinfo`, eventContactInfos);
    }

    deleteEventContactInfo(eventId: number, contactId: number): Observable<any> {
        return this.baseHttp.deleteData(`/event/id/${eventId}/contactinfo/${contactId}`);
    }

    getEventAttendeesExport(eventId: number): Observable<any> {
        return this.baseHttp.getFileWithApiFileName(`/event/${eventId}/attendees/download`, FileAcceptType.Excel);
    }

    getStagingWebcastAttendees(eventId: number): Observable<StagingWebcastAttendee[]> {
        return this.baseHttp.getData(`/event/stagingwebcastattendees/eventid/${eventId}`);
    }

    getStagingWebcastAttendee(stagingWebcastAttendeeId: number): Observable<StagingWebcastAttendee> {
        return this.baseHttp.getData(`/event/stagingwebcastattendee/id/${stagingWebcastAttendeeId}`);
    }

    updateStagingWebcastAttendee(stagingWebcastAttendee: StagingWebcastAttendee): Observable<StagingWebcastAttendee> {
        return this.baseHttp.putData(`/event/stagingwebcastattendee/id/${stagingWebcastAttendee.Id}`, stagingWebcastAttendee);
    }

    getEventInviteTemplate(eventId: number): Observable<EventInviteTemplate>{
        return this.baseHttp.getData(`/event/id/${eventId}/invitetemplate`);
    }

    getUpcomingEvents(): Observable<CalendarEvent[]>{
        return this.baseHttp.getData(`/event/upcomingevents`);
    }

    getUpcomingEventsEmail(
        eventEmailTemplateId: number,
        includeEventIds: number[],
        conferencesEmailSection: string
    ): Observable<any>{

        let data = {
            includeEventIds: includeEventIds,
            conferencesEmailSection: conferencesEmailSection
        };

        return this.baseHttp.getFileFromPost(
            `/event/upcomingeventsemail/${eventEmailTemplateId}`,
            `UpcomingEvents-General-Template.eml`,
            FileAcceptType.Email,
            data
        );
    }

    getEventEmailTemplates(): Observable<EventEmailTemplate[]> {
        return this.baseHttp.getData(`/event/eventemailtemplate`);
    }

    getEventEmailTemplateIncludedEventIds(eventEmailTemplateId: number): Observable<number[]> {
        return this.baseHttp.getData(`/event/eventemailtemplate/${eventEmailTemplateId}/inclusions`);
    }

    getEventEmailTemplateConferencesHtml(eventEmailTemplateId: number): Observable<EventEmailTemplateConferencesHtmlDto> {
        return this.baseHttp.getData(`/event/eventemailtemplate/${eventEmailTemplateId}/conferenceshtml`);
    }

    updateEventEmailTemplateConferencesHtml(
        eventEmailTemplateId: number,
        html: string
    ): Observable<EventInviteTemplate>{
        return this.baseHttp.postData(`/event/eventemailtemplate/${eventEmailTemplateId}/conferenceshtml`, { html: html });
    }

    updateEventEmailTemplateInclusions(eventEmailTemplateId: number, eventIds: number[]): Observable<any> {
        return this.baseHttp.postData(`/event/eventemailtemplate/${eventEmailTemplateId}/inclusions`, eventIds);
    }

    updateEventInviteTemplate(template: EventInviteTemplate): Observable<EventInviteTemplate>{
        return this.baseHttp.postData(`/event/id/${template.EventId}/invitetemplate`, template);
    }

    getEventQueryRecipients(eventId: any): Observable<number []> {
        return this.baseHttp.getData(`/event/id/${eventId}/queryrecipients`);
    }

    getEventInvitees(eventId: number): Observable<EventInvitee[]>{
        return this.baseHttp.getData(`/event/id/${eventId}/invitees`)
    }

    getMeetMaxQueuedEvents(): Observable<QueueMeetMaxEvent[]>{
        return this.baseHttp.getData(`/meetmax/eventqueue`)
    }

    createMeetMaxQueuedEvent(meetMaxId: string): Observable<any> {
        return this.baseHttp.postData(`/meetmax/eventqueue/meetmaxid/${meetMaxId}`, {});
    }

    deleteMeetMaxQueuedEvents(meetMaxEventIds: string[]): Observable<any> {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
            body: meetMaxEventIds
        } as HttpOptions;

        return this.baseHttp.deleteDataWithConfig(`/meetmax/eventqueue`, config);
    }
}

export interface QueueMeetMaxEvent {
    Id: number;
    MeetMaxId: string;
    CreatedOn: string | moment.Moment;
    CreatedBy: string;
}

export interface StagingWebcastAttendee {
    Id: number;
    WebcastId: string;
    FirstName: string;
    LastName: string;
    Company: string;
    Email: string;
    ContactId?: number;
    ConferenceMeetingId?: number;
    UpdatedOn: moment.Moment | string;
    ContactName: string;
    ContactAccountName: string;
    HasContactAccess: boolean;
    IsIgnored: boolean;
    CancelPrior: boolean;
    Notes: string;
}

export interface UpdateEventAttendanceDto {
    UpdateActivityAccountContactIds: number[];
    Adds: EventMeetingAttendeeDto[];
    Updates: EventMeetingAttendeeDto[];
    Removes: number[];
}

export interface EventMeetingAttendeeDto {
    ActivityId?: number;
    ContactId?: number;
    EventMeetingId: number;
    ActivityStatus: string;
    SubCategory: string;
    CallDate: moment.Moment | Date | string;
}

export interface EventContactInfo {
    EventId: number;
    ContactId: number;
    Notes: string;
    IsConfirmed: boolean;
}

export interface UpcomingEventAttendance {
    EventId: number;
    EventName: string;
    IsActive: boolean;
    EventMeetingId: number;
    EventMeetingName: string;
    AccountId: number;
    AccountName: string;
    Contact: UpcomingEventContact;
    Region: string;
    Market: string;
    SalesRole: string;
    SalesTeam: string;
    Broker: string;
    BeginDate: moment.Moment | string;
    EndDate: moment.Moment | string;
    Status: string;
    Interested: number;
    Registered: number;
    Category: string;
}

export interface UpcomingEventContact {
    Id: number;
    LastName: string;
    FirstName: string;
}

export interface EventInviteTemplate {
    Id: number;
    EventId: number;
    Subject: string;
    Body: string;
}

export interface EventEmailTemplateConferencesHtmlDto {
    Html: string;
}

export interface EventInvitee {
    ContactFirstName: string;
    ContactLastName: string;
    AccountName: string;
    MyAccount: boolean;
    Tier: string;
    InviteCount: number;
    LastInvited: string;

}
