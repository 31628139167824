import {Component, OnInit} from "@angular/core";
import {ColGroupDef, RowClickedEvent} from "ag-grid-community";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import * as moment from "moment";
import {combineLatest, forkJoin, of, throwError} from "rxjs";
import {catchError, debounceTime, map, startWith, switchMap, tap} from "rxjs/operators";
import {CommissionService} from "../../Shared/Services/commission.service";
import * as _ from "lodash";
import {AccountSplitCommission} from "../../../Models/AccountSplitCommission";
import {AccountMonthlyCommission} from "../../../Models/AccountMonthlyCommission";
import {accountTagComparator} from "../../Shared/ag-grid-options";
import {currencyFormatter, currencyRenderer} from "../../Shared/ag-grid-cell-renderers";
import {CommissionAccountRenderer} from "../../Widget/CellRenderers/commission-account-renderer";
import {AccountTagRenderer} from "../../Widget/CellRenderers/account-tag-renderer.component";
import {PercentChange} from "../../../Models/SplitCommissions";
import {UserService} from "../../Shared/Services/user.service";
import {User} from "../../../Models/User";
import * as daterangepicker from "bootstrap-daterangepicker";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {ReportService} from "../../Shared/Services/report.service";
import {Router} from "@angular/router";
import {AccountRoutePaths} from "../../Account/account-route-paths";
import {
  PercentageMultiLevelRendererComponent
} from "../../Widget/CellRenderers/percentage-multi-level-renderer.component";

const groupBySalesTeam = "Group by Sales Team";
const groupByBroker = "Group by Broker";

@Component({
    selector: "app-reports-commission-splits",
    templateUrl: "./commission-splits.component.html"
})
export class CommissionSplitsComponent extends BaseGridComponent<CommissionSplitRow> implements OnInit {

    columnDefs: ColGroupDef[];
    pinnedTopRowData: CommissionSplitRow[];
    accountMonthlyCommissions: AccountMonthlyCommission[];
    datePickerOptions: daterangepicker.Options = {};

    groupBy$ = of([
        groupBySalesTeam,
        groupByBroker
    ]);
    startYears$ = of(
        [...Array(6).keys()]
            .map(n => new Date().getFullYear() - n)
            .map(n => n.toString())
    );
    salesTeams$;
    brokers$;

    showSalesTeamFilter: boolean = false;
    salesTeamKeyValues: { key: string, value: string }[];
    showBrokerFilter: boolean = false;
    brokerKeyValues: { key: string, value: string }[];
    showGroupByFilter: boolean = false;

    features: string[] = [];

    intradayError: string;

    commissionSplitSearchForm: UntypedFormGroup = this.fb.group({
        accountName: this.fb.control(""),
        dateRange: this.fb.control({start: moment().startOf("year"), end: this.getLastBusinessDay()}),
        startYear: this.fb.control(moment().year().toString()),
        salesTeams: this.fb.control([]),
        brokers: this.fb.control([]),
        groupBy: this.fb.control(null)
    });

    frameworkComponents = {
        percentageRenderer: PercentageMultiLevelRendererComponent,
        accountRenderer: CommissionAccountRenderer,
        accountTagRenderer: AccountTagRenderer
    };

    get reportYear(): number {
        return moment(this.commissionSplitSearchForm.controls["startYear"].value).year();
    }

    get previousReportYear(): number {
        return moment().year(this.reportYear).subtract(1, "year").year();
    }

    get visibleRowData(): CommissionSplitRow[] {
        const rowData: CommissionSplitRow[] = [];
        this.gridApi.forEachLeafNode(node => rowData.push(node.data));
        return rowData;
    }

    constructor(
        private fb: UntypedFormBuilder,
        private commissionService: CommissionService,
        private userService: UserService,
        private reportService: ReportService,
        private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.groupDisplayType = "custom";
        this.gridOptions.suppressHorizontalScroll = false;
        this.gridOptions.suppressMakeColumnVisibleAfterUnGroup = true;
        this.gridOptions.rowClass = "groupable-row";

        this.userService.getCurrentUser().pipe(
            tap(user => {
                if ((user.ComdolRole === "Regional Director") || (user.ComdolRole === "Coordinator")) {
                    this.commissionSplitSearchForm.controls["groupBy"].patchValue(groupByBroker);
                    this.showSalesTeamFilter = false;
                    this.showBrokerFilter = true;
                    this.showGroupByFilter = false;
                } else if ((user.ComdolRole === "Management") || (user.ComdolRole === "Admin")) {
                    this.commissionSplitSearchForm.controls["groupBy"].patchValue(groupBySalesTeam);
                    this.showSalesTeamFilter = true;
                    this.showBrokerFilter = true;
                    this.showGroupByFilter = true;
                } else if ((user.ComdolRole !== "Management") && (user.ComdolRole !== "Admin")) {
                    this.commissionSplitSearchForm.controls["groupBy"].patchValue(groupByBroker);
                    this.showSalesTeamFilter = false;
                    this.showBrokerFilter = false;
                    this.showGroupByFilter = false;
                }
                this.features = user.Features;
            })
        ).subscribe(user => this.setRowData(user));
    }

    private setRowData(user: User): void {
        const filters$ = combineLatest([
            this.commissionSplitSearchForm.controls["accountName"].valueChanges.pipe(
                startWith(this.commissionSplitSearchForm.controls["accountName"].value)
            ),
            this.commissionSplitSearchForm.controls["salesTeams"].valueChanges.pipe(
                startWith(this.commissionSplitSearchForm.controls["salesTeams"].value)
            ),
            this.commissionSplitSearchForm.controls["brokers"].valueChanges.pipe(
                startWith(this.commissionSplitSearchForm.controls["brokers"].value)
            ),
            this.commissionSplitSearchForm.controls["groupBy"].valueChanges.pipe(
                startWith(this.commissionSplitSearchForm.controls["groupBy"].value)
            )
        ]);

        const startYear$ = combineLatest([
            this.reportService.getLatestReleaseDate(),
            this.commissionSplitSearchForm.controls["startYear"].valueChanges.pipe(
                startWith(this.commissionSplitSearchForm.controls["startYear"].value)
            )
        ]).pipe(
            tap(([releaseDate, startYear]) => {
                const reportYear = moment(startYear).year();
                const previousReportYear = moment().year(startYear).subtract(1, "year").year();
                const start = moment().year(startYear).startOf("year");
                const end = startYear === moment().year().toString() ? moment(releaseDate) : moment().year(startYear).endOf("year");
                this.datePickerOptions = {
                    ...this.datePickerOptions,
                    minDate: start,
                    maxDate: moment().year(startYear).endOf("year")
                }
                this.commissionSplitSearchForm.controls["dateRange"].patchValue({start, end}, {emitEvent: false});
                this.columnDefs = [
                    {
                        headerName: "",
                        children: [
                            {
                                field: "Tags",
                                headerName: "Votes",
                                headerClass: "hide-header",
                                headerTooltip: "Votes",
                                cellRenderer: "accountTagRenderer",
                                comparator: accountTagComparator,
                                width: 35,
                                minWidth: 35,
                                cellClass: "no-color"
                            },
                            {
                                field: "Account",
                                headerName: "Account",
                                flex: 2,
                                minWidth: 150,
                                maxWidth: 300,
                                cellRenderer: "agGroupCellRenderer",
                                cellRendererParams: {
                                    innerRenderer: "accountRenderer",
                                    reportYear: reportYear,
                                    suppressCount: true
                                },
                                showRowGroup: true,
                                cellClass: "no-color",
                                sort: "asc",
                                comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {

                                    if (!valueA && !valueB) {
                                        return 0;
                                    }
                                    if(!valueA) {
                                        return -1;
                                    }
                                    if(!valueB) {
                                        return 1;
                                    }

                                    if (nodeA.group && nodeB.group && isInverted) {
                                        return valueB.localeCompare(valueA);
                                    }

                                    return valueA.localeCompare(valueB);
                                }
                            },
                            {
                                field: "Location",
                                headerName: "Location",
                                valueGetter: params => [params.data?.City, params.data?.State, params.data?.Country].filter(x => !!x).join(", "),
                                flex: 1,
                                minWidth: 125,
                                cellClass: "no-color"
                            },
                            {field: "SalesTeam", headerName: "Sales Team", hide: true},
                            {field: "Broker", headerName: "Broker", hide: true},
                        ]
                    },
                    {
                        headerName: previousReportYear.toString(),
                        headerClass: "header-center",
                        children: [
                            {
                                field: "PreviousReportYearExSyndicate",
                                headerName: "Ex Syndicate",
                                flex: 1,
                                minWidth: 100,
                                aggFunc: "sum",
                                cellRenderer: currencyRenderer,
                                headerClass: "header-right",
                                type: "rightAligned",
                                cellClass: "lighter ag-right-aligned-cell"
                            },
                            {
                                field: "PreviousReportYearSyndicate",
                                headerName: "Syndicate",
                                flex: 1,
                                minWidth: 100,
                                aggFunc: "sum",
                                cellRenderer: currencyRenderer,
                                headerClass: "header-right",
                                type: "rightAligned",
                                cellClass: "lighter ag-right-aligned-cell"
                            },
                            {
                                field: "PreviousReportYearTotal",
                                headerName: "Total",
                                flex: 1,
                                minWidth: 100,
                                aggFunc: "sum",
                                cellRenderer: currencyRenderer,
                                headerClass: "header-right",
                                type: "rightAligned",
                            }
                        ]
                    },
                    {
                        headerName: reportYear.toString(),
                        headerClass: "header-center",
                        children: [
                            {
                                field: "ReportYearExSyndicate",
                                headerName: "Ex Syndicate",
                                flex: 1,
                                minWidth: 100,
                                aggFunc: "sum",
                                cellRenderer: currencyRenderer,
                                headerClass: "header-right",
                                type: "rightAligned",
                                cellClass: "lighter ag-right-aligned-cell"
                            },
                            {
                                field: "ReportYearSyndicate",
                                headerName: "Syndicate",
                                flex: 1,
                                minWidth: 100,
                                aggFunc: "sum",
                                cellRenderer: currencyRenderer,
                                headerClass: "header-right",
                                type: "rightAligned",
                                cellClass: "lighter ag-right-aligned-cell"
                            },
                            {
                                field: "ReportYearTotal",
                                headerName: "Total",
                                flex: 1,
                                minWidth: 100,
                                aggFunc: "sum",
                                cellRenderer: currencyRenderer,
                                headerClass: "header-right",
                                type: "rightAligned",
                            }
                        ]
                    },
                    {
                        headerName: "YOY",
                        headerClass: "header-center",
                        children: [
                            {
                                field: "YoyExSyndicate",
                                headerName: "Ex Syndicate",
                                flex: 1,
                                minWidth: 100,
                                valueGetter: params => {
                                    const percentChange = this.getCommissionPercentChange(params.data.ReportYearExSyndicate, params.data.PreviousReportYearExSyndicate);
                                    return {
                                        reportYearExSyndicate: params.data.ReportYearExSyndicate,
                                        previousYearReportExSyndicate: params.data.PreviousReportYearExSyndicate,
                                        iconClass: percentChange.IconClass,
                                        toString: () => percentChange.Value
                                    };
                                },
                                aggFunc: params => {
                                    const exSyndicateTotals = params.values.reduce((acc, curr) => {
                                        acc.reportYear += curr.reportYearExSyndicate;
                                        acc.previousReportYear += curr.previousYearReportExSyndicate;
                                        return acc;
                                    }, {reportYear: 0, previousReportYear: 0});
                                    const percentChange = this.getCommissionPercentChange(exSyndicateTotals.reportYear, exSyndicateTotals.previousReportYear);
                                    return {
                                        reportYearExSyndicate: exSyndicateTotals.reportYear,
                                        previousYearReportExSyndicate: exSyndicateTotals.previousReportYear,
                                        iconClass: percentChange.IconClass,
                                        toString: () => percentChange.Value
                                    }
                                },
                                cellRenderer: "percentageRenderer",
                                headerClass: "header-right",
                                type: "rightAligned",
                            },
                            {
                                field: "YoyChange",
                                headerName: "Total",
                                flex: 1,
                                minWidth: 80,
                                valueGetter: params => {
                                    const percentChange = this.getCommissionPercentChange(params.data.ReportYearTotal, params.data.PreviousReportYearTotal);
                                    return {
                                        reportYearTotal: params.data.ReportYearTotal,
                                        previousReportYearTotal: params.data.PreviousReportYearTotal,
                                        iconClass: percentChange.IconClass,
                                        toString: () => percentChange.Value
                                    };
                                },
                                aggFunc: params => {
                                    const totals = params.values.reduce((acc, curr) => {
                                        acc.reportYear += curr.reportYearTotal;
                                        acc.previousReportYear += curr.previousReportYearTotal;
                                        return acc;
                                    }, {reportYear: 0, previousReportYear: 0});
                                    const percentChange = this.getCommissionPercentChange(totals.reportYear, totals.previousReportYear);
                                    return {
                                        reportYearTotal: totals.reportYear,
                                        previousReportYearTotal: totals.previousReportYear,
                                        iconClass: percentChange.IconClass,
                                        toString: () => percentChange.Value
                                    }
                                },
                                cellRenderer: "percentageRenderer",
                                headerClass: "header-right",
                                type: "rightAligned",
                            }
                        ]
                    },
                    {
                        headerName: "Account Ex Syndicate",
                        headerClass: "header-center",
                        children: [
                            {
                                field: "PreviousReportYearExSynAccountTotal",
                                headerName: previousReportYear.toString(),
                                flex: 1,
                                minWidth: 100,
                                valueGetter: params => {
                                    return {
                                        accountIds: [params.data.AccountId],
                                        toString: () => params.data.PreviousReportYearExSynAccountTotal
                                    }
                                },
                                aggFunc: params => {
                                    const uniqueAccountIds = [...new Set<number>(_.flatMap(params.values.map(x => x.accountIds)))];
                                    return {
                                        accountIds: uniqueAccountIds,
                                        toString: () => _.sumBy(uniqueAccountIds, id => this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === id), previousReportYear, "S"))
                                    }
                                },
                                cellRenderer: currencyRenderer,
                                headerClass: "header-right",
                                type: "rightAligned",
                                cellClass: "info ag-right-aligned-cell"
                            },
                            {
                                field: "ReportYearExSynAccountTotal",
                                headerName: reportYear.toString(),
                                flex: 1,
                                minWidth: 100,
                                valueGetter: params => {
                                    return {
                                        accountIds: [params.data.AccountId],
                                        toString: () => params.data.ReportYearExSynAccountTotal
                                    }
                                },
                                aggFunc: params => {
                                    const uniqueAccountIds = [...new Set<number>(_.flatMap(params.values.map(x => x.accountIds)))];
                                    return {
                                        accountIds: uniqueAccountIds,
                                        toString: () => _.sumBy(uniqueAccountIds, id => this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === id), reportYear, "S"))
                                    }
                                },
                                cellRenderer: currencyRenderer,
                                headerClass: "header-right",
                                type: "rightAligned",
                                cellClass: "info ag-right-aligned-cell"
                            },
                            {
                                field: "YoyChange",
                                headerName: "% Change",
                                flex: 1,
                                minWidth: 80,
                                valueGetter: params => {
                                    const percentChange = this.getCommissionPercentChange(params.data.ReportYearExSynAccountTotal, params.data.PreviousReportYearExSynAccountTotal);
                                    return {
                                        reportYearExSynAccountTotal: params.data.ReportYearExSynAccountTotal,
                                        previousReportYearExSynAccountTotal: params.data.PreviousReportYearExSynAccountTotal,
                                        iconClass: percentChange.IconClass,
                                        value: percentChange.Value,
                                        toString: () => percentChange.Value
                                    };
                                },
                                aggFunc: params => {
                                    const totals = params.values.reduce((acc, curr) => {
                                        acc.reportYear += curr.reportYearExSynAccountTotal;
                                        acc.previousReportYear += curr.previousReportYearExSynAccountTotal;
                                        return acc;
                                    }, {reportYear: 0, previousReportYear: 0});
                                    const percentChange = this.getCommissionPercentChange(totals.reportYear, totals.previousReportYear);
                                    return {
                                        reportYearExSynAccountTotal: totals.reportYear,
                                        previousReportYearExSynAccountTotal: totals.previousReportYear,
                                        iconClass: percentChange.IconClass,
                                        toString: () => percentChange.Value
                                    }
                                },
                                cellRenderer: "percentageRenderer",
                                headerClass: "header-right",
                                type: "rightAligned",
                            }
                        ]
                    },
                    {
                        headerName: "Account Totals",
                        headerClass: "header-center",
                        children: [
                            {
                                field: "PreviousReportYearAccountTotal",
                                headerName: previousReportYear.toString(),
                                flex: 1,
                                minWidth: 100,
                                valueGetter: params => {
                                    return {
                                        accountIds: [params.data.AccountId],
                                        toString: () => params.data.PreviousReportYearAccountTotal
                                    }
                                },
                                aggFunc: params => {
                                    const uniqueAccountIds = [...new Set<number>(_.flatMap(params.values.map(x => x.accountIds)))];
                                    return {
                                        accountIds: uniqueAccountIds,
                                        toString: () => _.sumBy(uniqueAccountIds, id => this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === id), previousReportYear))
                                    }
                                },
                                cellRenderer: currencyRenderer,
                                headerClass: "header-right",
                                type: "rightAligned",
                                cellClass: "info ag-right-aligned-cell"
                            },
                            {
                                field: "ReportYearAccountTotal",
                                headerName: reportYear.toString(),
                                flex: 1,
                                minWidth: 100,
                                valueGetter: params => {
                                    return {
                                        accountIds: [params.data.AccountId],
                                        toString: () => params.data.ReportYearAccountTotal
                                    }
                                },
                                aggFunc: params => {
                                    const uniqueAccountIds = [...new Set<number>(_.flatMap(params.values.map(x => x.accountIds)))];
                                    return {
                                        accountIds: uniqueAccountIds,
                                        toString: () => _.sumBy(uniqueAccountIds, id => this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === id), reportYear))
                                    }
                                },
                                cellRenderer: currencyRenderer,
                                headerClass: "header-right",
                                type: "rightAligned",
                                cellClass: "info ag-right-aligned-cell"
                            },
                            {
                                field: "AccountTotalChange",
                                headerName: "% Change",
                                flex: 1,
                                minWidth: 80,
                                valueGetter: params => {
                                    const percentChange = this.getCommissionPercentChange(params.data.ReportYearAccountTotal, params.data.PreviousReportYearAccountTotal);
                                    return {
                                        accountIds: [params.data.AccountId],
                                        iconClass: percentChange.IconClass,
                                        value: percentChange.Value,
                                        toString: () => percentChange.Value
                                    };
                                },
                                aggFunc: params => {

                                    const uniqueAccountIds = [...new Set<number>(_.flatMap(params.values.map(x => x.accountIds)))];
                                    const reportYearAccountTotal = _.sumBy(uniqueAccountIds, id => this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === id), reportYear));
                                    const previousReportYearAccountTotal = _.sumBy(uniqueAccountIds, id => this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === id), previousReportYear));
                                    const percentChange = this.getCommissionPercentChange(reportYearAccountTotal, previousReportYearAccountTotal);
                                    return {
                                        accountIds: uniqueAccountIds,
                                        iconClass: percentChange.IconClass,
                                        toString: () => percentChange.Value
                                    }
                                },
                                cellRenderer: "percentageRenderer",
                                headerClass: "header-right",
                                type: "rightAligned",
                                cellClass: "info ag-right-aligned-cell"
                            }
                        ]
                    }
                ];
            })
        )

        const dates$ = combineLatest([
            this.commissionSplitSearchForm.controls["dateRange"].valueChanges.pipe(
                startWith(this.commissionSplitSearchForm.controls["dateRange"].value),
            ),
            startYear$
        ]).pipe(
          // We put this in a timeout as startYear$ modifies the grid config and will not respond to this method right away
            debounceTime(250),
            tap(() => {
                this.gridApi?.showLoadingOverlay();
            }),
            switchMap(([dateRange, [releaseDate, startYear]]) => {
                return forkJoin([
                    this.commissionService.getBrokerSplits(startYear),
                    this.commissionService.getCommissionSplits(dateRange.start, dateRange.end).pipe(
                        map(commissionSplits => {
                            if ((user.ComdolRole === "Regional Director") || (user.ComdolRole === "Coordinator")) {
                                return commissionSplits.filter(c => c.Team === user.Team.Name);
                            } else if ((user.ComdolRole !== "Management") && (user.ComdolRole !== "Admin")) {
                                return commissionSplits.filter(
                                    c => c.Broker.trim() === `${user.LastName}, ${user.FirstName}`.trim());
                            }
                            return commissionSplits
                        })
                    ),
                    this.commissionService.getAllCommissionsYearOverYear(dateRange.start, dateRange.end),
                    of(startYear)
                ]);
            }),
            tap(([brokerSplits, commissionSplits]) => {
                const salesTeams = [...new Set<string>(commissionSplits.map(split => split.Team).sort())];
                this.salesTeams$ = of(salesTeams);
                this.salesTeamKeyValues = salesTeams.map(st => {
                    return { key: st, value: st };
                });
                const brokers = [...new Set<string>(commissionSplits.map(split => split.Broker).sort())];
                this.brokers$ = of(brokers);
                this.brokerKeyValues = brokers.map(b => {
                    return { key: b, value: b };
                });
            }),
        )

        this.rowData$ = combineLatest([
            dates$,
            filters$
        ]).pipe(
            map(([[brokerSplitChanges, commissionSplits, accountMonthlyCommissions, startYear], [accountName, salesTeams, brokers, groupBy]]) => {
                this.gridColumnApi?.setRowGroupColumns(groupBy === groupBySalesTeam ? ["SalesTeam", "Broker"] : ["Broker"]);
                this.accountMonthlyCommissions = accountMonthlyCommissions;
                const reportYear = moment(startYear).year();
                const previousReportYear = moment(startYear).subtract(1, "year").year();

                return _.chain(commissionSplits)
                    .filter(split => (salesTeams.length > 0 ? salesTeams.includes(split.Team) : true) && (brokers.length > 0 ? brokers.includes(split.Broker) : true))
                    .flatMap(split => {
                        const accounts = new Map<number, AccountSplitCommission[]>();
                        split.Commission
                            .filter(commission => (commission.AccountName?.toLowerCase() ?? "").includes(accountName?.toLowerCase()))
                            .forEach(c => {
                                if (accounts.has(c.AccountId)) {
                                    accounts.get(c.AccountId).push(c);
                                } else {
                                    accounts.set(c.AccountId, [c]);
                                }
                            });
                        return [...accounts].map(([accountId, commissions]) => {
                            const brokerSplit = brokerSplitChanges
                                .filter(b => b.AccountId === accountId && b.Broker === split.Broker);
                            const commission = commissions[0];
                            const commissionSplitRow = {} as CommissionSplitRow;
                            commissionSplitRow.Broker = split.Broker;
                            commissionSplitRow.SalesTeam = split.Team;
                            commissionSplitRow.AccountId = commission.AccountId;
                            commissionSplitRow.Account = commission.AccountName;
                            commissionSplitRow.ParentComdolId = commission.ParentComdolId;
                            commissionSplitRow.ComdolId = commission.ComdolId;
                            commissionSplitRow.Status = commission.AccountStatus;
                            commissionSplitRow.Tags = commission.Tags;
                            commissionSplitRow.BrokerSplitStatus = brokerSplit?.length === 0 ? "" : brokerSplit[0].Status;
                            commissionSplitRow.City = commission.City;
                            commissionSplitRow.State = commission.State;
                            commissionSplitRow.Country = commission.Country;

                            let exSyndicateAccountSplitCommissions = commissions.filter(c => c.Category !== "S");
                            commissionSplitRow.ReportYearExSyndicate = this.getCommissionSum(exSyndicateAccountSplitCommissions, reportYear);
                            commissionSplitRow.PreviousReportYearExSyndicate = this.getCommissionSum(exSyndicateAccountSplitCommissions, previousReportYear);

                            let syndicateAccountSplitCommissions = commissions.filter(c => c.Category === "S");
                            commissionSplitRow.ReportYearSyndicate = this.getCommissionSum(syndicateAccountSplitCommissions, reportYear);
                            commissionSplitRow.PreviousReportYearSyndicate = this.getCommissionSum(syndicateAccountSplitCommissions, previousReportYear);

                            commissionSplitRow.ReportYearTotal = _.chain([commissionSplitRow.ReportYearExSyndicate + commissionSplitRow.ReportYearSyndicate]).sum().round(4).value();
                            commissionSplitRow.PreviousReportYearTotal = _.chain([commissionSplitRow.PreviousReportYearExSyndicate + commissionSplitRow.PreviousReportYearSyndicate]).sum().round(4).value();

                            const uniqueAccountIds = [...new Set<number>(commissions.map(c => c.AccountId))];
                            commissionSplitRow.ReportYearExSynAccountTotal = _.sumBy(uniqueAccountIds, id => this.getAccountTotal(accountMonthlyCommissions.filter(y => y.AccountId === id), reportYear, "S"));
                            commissionSplitRow.PreviousReportYearExSynAccountTotal = _.sumBy(uniqueAccountIds, id => this.getAccountTotal(accountMonthlyCommissions.filter(y => y.AccountId === id), previousReportYear, "S"));

                            commissionSplitRow.ReportYearAccountTotal = _.sumBy(uniqueAccountIds, id => this.getAccountTotal(accountMonthlyCommissions.filter(y => y.AccountId === id), reportYear));
                            commissionSplitRow.PreviousReportYearAccountTotal = _.sumBy(uniqueAccountIds, id => this.getAccountTotal(accountMonthlyCommissions.filter(y => y.AccountId === id), previousReportYear));

                            return commissionSplitRow;
                        });
                    })
                    .value();
            }),
            tap(rowData => {
                const uniqueAccountIds = [...new Set(rowData.map(d => d.AccountId))];
                this.pinnedTopRowData = [{
                    Account: "Total",
                    ReportYearExSyndicate: _.sum(rowData.map(r => r.ReportYearExSyndicate)),
                    ReportYearSyndicate: _.sum(rowData.map(r => r.ReportYearSyndicate)),
                    ReportYearTotal: _.sum(rowData.map(r => r.ReportYearTotal)),
                    PreviousReportYearExSyndicate: _.sum(rowData.map(r => r.PreviousReportYearExSyndicate)),
                    PreviousReportYearSyndicate: _.sum(rowData.map(r => r.PreviousReportYearSyndicate)),
                    PreviousReportYearTotal: _.sum(rowData.map(r => r.PreviousReportYearTotal)),
                    ReportYearExSynAccountTotal: _.sumBy(uniqueAccountIds, id => this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === id), this.reportYear, "S")),
                    PreviousReportYearExSynAccountTotal: _.sumBy(uniqueAccountIds, id => this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === id), this.previousReportYear, "S")),
                    ReportYearAccountTotal: _.sumBy(uniqueAccountIds, id => this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === id), this.reportYear)),
                    PreviousReportYearAccountTotal: _.sumBy(uniqueAccountIds, id => this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === id), this.previousReportYear)),
                } as CommissionSplitRow];
                this.gridApi?.hideOverlay();
            })
        );
    }

    private getCommissionSum(accountSplitCommissions: AccountSplitCommission[], year: number): number {
        return _.chain(accountSplitCommissions)
            .filter(x => x.Year === year)
            .map(x => x.Amount)
            .sum()
            .round(4)
            .value();
    }

    private getCommissionPercentChange(year: number, previousYear: number): PercentChange {
        if (previousYear === 0) {
            return {Value: NaN, Display: "N/A", IconClass: "fa fa-empire neutral"};
        } else {
            const change = _.round((((year - previousYear) / Math.abs(previousYear)) * 100), 2);
            const iconClass = change > 0 ? "fa fa-arrow-up in-the-black" : "fa fa-arrow-down in-the-red";
            if (change > 0) {
                const value = _.min([change, 500]) == 500 ? NaN : change;
                const display = change >= 500 ? "N/A" : `${change.toFixed(2)}%`;
                return {Value: value, Display: display, IconClass: iconClass};

            } else {
                const value = _.max([change, -100]) == -100 ? NaN : change;
                const display = change <= -100 ? "N/A" : `${change.toFixed(2)}%`;
                return {Value: value, Display: display, IconClass: iconClass};
            }
        }
    }

    private getAccountTotal(monthlyCommissions: AccountMonthlyCommission[], year: number, excludeCategory: string = ""): number {
        let accountTotals: number = -1;
        if (monthlyCommissions?.length > 0) {
            const matchingAccount = monthlyCommissions[0];
            accountTotals = _.chain(matchingAccount.MonthlyCommissions)
                .filter(c => c.Year === year)
                .filter(c => c.Category !== excludeCategory)
                .sumBy(m => m.Commission)
                .value();
        }
        return accountTotals;
    }

    private getLastBusinessDay(): moment.Moment {
        const now = moment();
        switch (now.day()) {
            case 0:
            case 1:
            case 6:
                return now.subtract(6, "days").day(5);
            default:
                return now.subtract(1, "days");
        }
    }

    rowClicked($event: RowClickedEvent): void {
        if ($event.data?.AccountId && !$event.data?.Tags.includes("NoAccess")) {
            this.router.navigate([AccountRoutePaths.AccountDetail, $event.data.AccountId]);
        }
    }

    exportToPdf(): void {
        (pdfMake.vfs as any) = pdfFonts.pdfMake.vfs;
        const docDefinition = this.createPdfDocument();
        pdfMake.createPdf(docDefinition).download("SplitCommissions.pdf");
    }

    private createPdfDocument(): pdfMake.TDocumentDefinitions {
        return {
            pageOrientation: "landscape",
            pageSize: "LETTER",
            header: this.createHeader(`Broker Commission Splits (${moment(this.commissionSplitSearchForm.controls["dateRange"].value.start).format("L")} - ${moment(this.commissionSplitSearchForm.controls["dateRange"].value.end).format("L")})`),
            content: this.getPdfExportContent(),
            styles: {
                pageHeader: {
                    alignment: "center"
                },
                brokerHeader: {
                    alignment: "left"
                },
                splitDataTable: {
                    fontSize: 8
                },
                splitDataTableGroupHeader: {
                    alignment: "center",
                    fillColor: "#f5f5f5"
                },
                textHeader: {
                    fillColor: "#f5f5f5"
                },
                dataHeader: {
                    alignment: "right",
                    fillColor: "#f5f5f5"
                },
                splitDataValue: {
                    alignment: "right"
                },
                splitDataSubTotal: {
                    alignment: "right"
                },
                splitDataAccountTotal: {
                    alignment: "right"
                },
                splitDataTableTotal: {
                    fillColor: "#f5f5f5",
                },
                splitDataTableDataTotal: {
                    fillColor: "#f5f5f5",
                    alignment: "right"
                }
            },
            pageMargins: [15, 25, 15, 5]
        };
    }

    private createHeader(pdfTitle: string) {
        return (currentPage: number, pageCount: number, pageSize: number) => {
            return {
                columns: [
                    {width: "15%", text: ""},
                    {width: "*", text: pdfTitle, alignment: "center", margin: [0, 5, 0, 0]},
                    {
                        width: "15%",
                        text: "Page: " + currentPage + " of " + pageCount,
                        alignment: "right",
                        margin: [0, 5, 15, 0]
                    }
                ]
            };
        };
    };

    private getPdfExportContent(): any[] {
        const groupedRows = new Map<string, CommissionSplitRow[]>();
        this.visibleRowData
            .sort((a, b) => a.Broker.localeCompare(b.Broker))
            .forEach(data => {
                if (groupedRows.has(data.Broker)) {
                    groupedRows.get(data.Broker).push(data);
                } else {
                    groupedRows.set(data.Broker, [data]);
                }
            });

        let exportData = [];
        let brokerCount: number = 0;
        [...groupedRows].forEach(([broker, rows]) => {
            exportData.push({text: broker, style: "brokerHeader"});

            const splitTableBody = [];
            splitTableBody.push([
                {colSpan: 2, text: " ", style: "splitDataTableGroupHeader"}, {},
                {colSpan: 3, text: this.previousReportYear, style: "splitDataTableGroupHeader"}, {}, {},
                {colSpan: 3, text: this.reportYear, style: "splitDataTableGroupHeader"}, {}, {},
                {colSpan: 2, text: "YOY", style: "splitDataTableGroupHeader"}, {},
                {colSpan: 3, text: "Account Totals", style: "splitDataTableGroupHeader"}, {}, {}]);
            splitTableBody.push([
                {style: "textHeader", text: "Account"},
                {style: "textHeader", text: "Location"},
                {style: "dataHeader", text: "Ex Syndicate"},
                {style: "dataHeader", text: "Syndicate"},
                {style: "dataHeader", text: "Total"},
                {style: "dataHeader", text: "Ex Syndicate"},
                {style: "dataHeader", text: "Syndicate"},
                {style: "dataHeader", text: "Total"},
                {style: "dataHeader", text: "Ex Syndicate"},
                {style: "dataHeader", text: "%Chg"},
                {style: "dataHeader", text: this.previousReportYear},
                {style: "dataHeader", text: this.reportYear},
                {style: "dataHeader", text: "%Chg"}
            ]);

            rows.forEach(row => {
                let accountStatus = "";
                if (row.BrokerSplitStatus == "Add") {
                    accountStatus = " (+)";
                }
                if (row.BrokerSplitStatus == "Remove") {
                    accountStatus = " (-)";
                }

                const lastYearTotal = this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === row.AccountId), this.previousReportYear);
                const thisYearTotal = this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === row.AccountId), this.reportYear);
                splitTableBody.push([
                    row.Account + accountStatus,
                    [row.City, (row.State || row.Country)].filter(x => !!x).join(", "),
                    {
                        style: "splitDataValue",
                        text: currencyFormatter(row.PreviousReportYearExSyndicate, 0)
                    },
                    {
                        style: "splitDataValue",
                        text: currencyFormatter(row.PreviousReportYearSyndicate, 0)
                    },
                    {
                        style: "splitDataSubTotal",
                        text: currencyFormatter(row.PreviousReportYearTotal, 0)
                    },
                    {
                        style: "splitDataValue",
                        text: currencyFormatter(row.ReportYearExSyndicate, 0)
                    },
                    {
                        style: "splitDataValue",
                        text: currencyFormatter(row.ReportYearSyndicate, 0)
                    },
                    {
                        style: "splitDataSubTotal",
                        text: currencyFormatter(row.ReportYearTotal, 0)
                    },
                    {
                        style: "splitDataSubTotal",
                        text: this.getCommissionPercentChange(row.ReportYearExSyndicate, row.PreviousReportYearExSyndicate).Display
                    },
                    {
                        style: "splitDataSubTotal",
                        text: this.getCommissionPercentChange(row.ReportYearTotal, row.PreviousReportYearTotal).Display
                    },
                    {
                        style: "splitDataAccountTotal",
                        text: currencyFormatter(this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === row.AccountId), this.previousReportYear))
                    },
                    {
                        style: "splitDataAccountTotal",
                        text: currencyFormatter(this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === row.AccountId), this.reportYear))
                    },
                    {
                        style: "splitDataAccountTotal",
                        text: this.getCommissionPercentChange(thisYearTotal, lastYearTotal).Display
                    }
                ]);
            });

            const uniqueAccountIds = [...new Set(rows.map(r => r.AccountId))];
            const previousReportYearExSyndicate = _.sum(rows.map(r => r.PreviousReportYearExSyndicate));
            const previousReportYearSyndicate = _.sum(rows.map(r => r.PreviousReportYearSyndicate));
            const previousReportYearTotal = _.sum(rows.map(r => r.PreviousReportYearTotal));
            const previousReportYearAccountTotal = _.sum(rows.map(r => r.PreviousReportYearAccountTotal));
            const reportYearExSyndicate = _.sum(rows.map(r => r.ReportYearExSyndicate));
            const reportYearSyndicate = _.sum(rows.map(r => r.ReportYearSyndicate));
            const reportYearTotal = _.sum(rows.map(r => r.ReportYearTotal));
            const reportYearAccountTotal = _.sum(rows.map(r => r.ReportYearAccountTotal));
            splitTableBody.push([
                {style: "splitDataTableTotal", text: "Total"},
                {style: "splitDataTableTotal", text: ""},
                {
                    style: "splitDataTableDataTotal",
                    text: currencyFormatter(previousReportYearExSyndicate, 0)
                },
                {
                    style: "splitDataTableDataTotal",
                    text: currencyFormatter(previousReportYearSyndicate, 0)
                },
                {
                    style: "splitDataTableDataTotal",
                    text: currencyFormatter(previousReportYearTotal, 0)
                },
                {
                    style: "splitDataTableDataTotal",
                    text: currencyFormatter(reportYearExSyndicate, 0)
                },
                {
                    style: "splitDataTableDataTotal",
                    text: currencyFormatter(reportYearSyndicate, 0)
                },
                {
                    style: "splitDataTableDataTotal",
                    text: currencyFormatter(reportYearTotal, 0)
                },
                {
                    style: "splitDataTableDataTotal",
                    text: this.getCommissionPercentChange(reportYearExSyndicate, previousReportYearExSyndicate).Display
                },
                {
                    style: "splitDataTableDataTotal",
                    text: this.getCommissionPercentChange(reportYearTotal, previousReportYearTotal).Display
                },
                {
                    style: "splitDataTableDataTotal",
                    text: currencyFormatter(_.sumBy(uniqueAccountIds, id => this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === id), this.previousReportYear)), 0)
                },
                {
                    style: "splitDataTableDataTotal",
                    text: currencyFormatter(_.sumBy(uniqueAccountIds, id => this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === id), this.reportYear)), 0)
                },
                {
                    style: "splitDataTableDataTotal",
                    text: this.getCommissionPercentChange(reportYearAccountTotal, previousReportYearAccountTotal).Display
                }
            ]);

            exportData.push({
                pageBreak: (brokerCount++ === groupedRows.size - 1) ? "" : "after",
                style: "splitDataTable",
                table: {
                    headerRows: 2,
                    widths: ["17%", "8%",
                        "7%", "7%", "7%",
                        "7%", "7%", "7%",
                        "7%", "6%",
                        "7%", "7%", "6%"],
                    body: splitTableBody
                },
                layout: {
                    hLineWidth: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return 0;
                        }
                        return (i === node.table.headerRows) ? 2 : 1;
                    },
                    vLineWidth: function (i) {
                        return 0;
                    },
                    hLineColor: function (i) {
                        return i === 1 ? "black" : "#aaa";
                    },
                    paddingLeft: function (i) {
                        return i === 0 ? 0 : 4;
                    },
                    paddingRight: function (i, node) {
                        return (i === node.table.widths.length - 1) ? 0 : 4;
                    }
                }
            });
        });
        return exportData;
    }

    public exportToCSV() {
        const headers = this.getCSVHeaders();
        const exportData = this.getCSVData();
        let csv = this.convertArrayOfObjectsToCSV(exportData, headers);
        if (csv == null) return;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = "data:text/csv;charset=utf-8," + csv;
        }

        const fileName = "CommissionSplitsExport_".concat(moment().format()).concat(".csv");
        const encodedExportData = encodeURI(csv);

        const link = document.createElement("a");
        link.setAttribute("display", "none");
        link.setAttribute("href", encodedExportData);
        link.setAttribute("download", fileName);
        link.click();
        link.remove();
    }

    private convertArrayOfObjectsToCSV(exportData: ExportSplitCommission[], exportHeaders: string[]) {
        const columnDelimiter = ",";
        const lineDelimiter = "\r\n";

        const keys: string[] = Object.keys(exportData[0]);

        let result = "";

        if (exportHeaders && exportHeaders.length > 0) {
            result += exportHeaders.join(columnDelimiter);
        } else {
            result += keys.join(columnDelimiter);
        }
        result += lineDelimiter;

        exportData.forEach(dataRow => {
            let counter = 0;
            keys.forEach((key) => {
                if (counter > 0) result += columnDelimiter;

                result += !dataRow[key] ? "" : `"${dataRow[key]}"`;
                counter++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    private getCSVHeaders(): string[] {
        return [
            "Broker First Name",
            "Broker Last Name",
            `${this.previousReportYear} Split %`,
            `${this.reportYear} Split %`,
            "Sales Team",
            "Account",
            "Parent Comdol ID",
            "Comdol ID",
            "Instnum",
            "Status",
            "City",
            "State",
            "Country",
            `${this.previousReportYear} Ex-Syndicate`,
            `${this.previousReportYear} Syndicate`,
            `${this.previousReportYear} Split Total`,
            `${this.reportYear} Ex-Syndicate`,
            `${this.reportYear} Syndicate`,
            `${this.reportYear} Split Total`,
            "YOY Ex Syndicate",
            "YOY Total",
            `${this.previousReportYear} Ex-Syndicate Account Total`,
            `${this.reportYear} Ex-Syndicate Account Total`,
            "Ex-Syndicate Total % Change",
            `${this.previousReportYear} Account Total`,
            `${this.reportYear} Account Total`,
            "Account Total % Change"
        ]
    }

    private getCSVData(): ExportSplitCommission[] {
        const groupedRows = new Map<string, CommissionSplitRow[]>();
        this.visibleRowData
            .sort((a, b) => a.Broker.localeCompare(b.Broker))
            .forEach(data => {
                if (groupedRows.has(data.Broker)) {
                    groupedRows.get(data.Broker).push(data);
                } else {
                    groupedRows.set(data.Broker, [data]);
                }
            });

        let exportData: ExportSplitCommission[] = [];
        if (groupedRows?.size > 0) {
            [...groupedRows].forEach(([broker, rows]) => {
                rows.forEach(row => {
                    const brokerNameParts: string[] = broker.split(",");
                    const lastYearAccountExSynTotal = this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === row.AccountId), this.previousReportYear, "S");
                    const thisYearAccountExSynTotal = this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === row.AccountId), this.reportYear, "S");
                    const lastYearAccountTotal = this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === row.AccountId), this.previousReportYear);
                    const thisYearAccountTotal = this.getAccountTotal(this.accountMonthlyCommissions.filter(y => y.AccountId === row.AccountId), this.reportYear);

                    exportData.push({
                        brokerFirstName: brokerNameParts[1]?.trim() ?? "",
                        brokerLastName: brokerNameParts[0]?.trim() ?? "",
                        lastYearSplit: this.getBrokerSplitPercentageForTotalAccount(lastYearAccountTotal, row.PreviousReportYearTotal),
                        thisYearSplit: this.getBrokerSplitPercentageForTotalAccount(thisYearAccountTotal, row.ReportYearTotal),
                        salesTeam: row.SalesTeam,
                        accountName: row.Account,
                        parentComdolID: row.ParentComdolId,
                        comdolID: row.ComdolId,
                        accountId: row.AccountId,
                        status: row.Status,
                        city: row.City,
                        state: row.State,
                        country: row.Country,
                        lastYearExSyndicate: currencyFormatter(row.PreviousReportYearExSyndicate, 2),
                        lastYearSyndicate: currencyFormatter(row.PreviousReportYearSyndicate, 2),
                        lastYearTotal: currencyFormatter(row.PreviousReportYearTotal, 2),
                        thisYearExSyndicate: currencyFormatter(row.ReportYearExSyndicate, 2),
                        thisYearSyndicate: currencyFormatter(row.ReportYearSyndicate, 2),
                        thisYearTotal: currencyFormatter(row.ReportYearTotal, 2),
                        yoyExSyndicate: this.getCommissionPercentChange(row.ReportYearExSyndicate, row.PreviousReportYearExSyndicate).Display,
                        yoyTotal: this.getCommissionPercentChange(row.ReportYearTotal, row.PreviousReportYearTotal).Display,
                        lastYearAccountExSynTotal: currencyFormatter(lastYearAccountExSynTotal, 2),
                        thisYearAccountExSynTotal: currencyFormatter(thisYearAccountExSynTotal, 2),
                        accountExSynTotalPercentage: this.getCommissionPercentChange(thisYearAccountExSynTotal, lastYearAccountExSynTotal).Display,
                        lastYearAccountTotal: currencyFormatter(lastYearAccountTotal, 2),
                        thisYearAccountTotal: currencyFormatter(thisYearAccountTotal, 2),
                        accountTotalPercentage: this.getCommissionPercentChange(thisYearAccountTotal, lastYearAccountTotal).Display
                    });
                });
            });
        }

        return exportData.sort((a, b) => a.brokerFirstName.localeCompare(b.brokerFirstName));
    }

    private getBrokerSplitPercentageForTotalAccount(accountTotals: number, brokerSplitTotals: number): string {
        if (accountTotals > 0) {
            let brokerSplitPercentage: number = _.round((brokerSplitTotals / accountTotals) * 100, 2);
            return brokerSplitPercentage.toString() + "%";
        }
        return "0%";
    }
}

interface CommissionSplitRow {
    AccountId: number;
    Account: string;
    ParentComdolId: string;
    ComdolId: string;
    Status: string;
    Tags: string[];
    City: string;
    State: string;
    Country: string;
    Broker: string;
    BrokerSplitStatus: string;
    SalesTeam: string;
    ReportYearExSyndicate: number;
    ReportYearSyndicate: number;
    ReportYearTotal: number;
    PreviousReportYearExSyndicate: number;
    PreviousReportYearSyndicate: number;
    PreviousReportYearTotal: number;
    YoyExSyndicate: number;
    YoyChange: number;
    ReportYearExSynAccountTotal: number;
    PreviousReportYearExSynAccountTotal: number;
    AccountExSynTotalChange: number;
    ReportYearAccountTotal: number;
    PreviousReportYearAccountTotal: number;
    AccountTotalChange: number;
}

interface ExportSplitCommission {
    brokerFirstName: string;
    brokerLastName: string;
    lastYearSplit: string;
    thisYearSplit: string;
    salesTeam: string;
    accountName: string;
    parentComdolID: string;
    comdolID: string;
    accountId: number;
    status: string;
    city: string;
    state: string;
    country: string;
    lastYearExSyndicate: string;
    lastYearSyndicate: string;
    lastYearTotal: string;
    thisYearExSyndicate: string;
    thisYearSyndicate: string;
    thisYearTotal: string;
    yoyExSyndicate: string;
    yoyTotal: string;
    lastYearAccountExSynTotal: string;
    thisYearAccountExSynTotal: string;
    accountExSynTotalPercentage: string;
    lastYearAccountTotal: string;
    thisYearAccountTotal: string;
    accountTotalPercentage: string;
}
