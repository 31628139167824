<div class="modal-content">
    <div class="modal-header">

        <div class="pull-right flex-hbox" style="width: 500px;">
            <div class="flex" *ngFor="let i of [1,2,3]">
                <div *ngFor="let statusSummary of statusSummaries.slice((i - 1)  * 3, i * 3)">
                    <span style="font-weight: bold; width: 105px; display: inline-block;">{{statusSummary.Name}}</span> {{statusSummary.Count}}
                </div>
            </div>
        </div>

        <h3 class="modal-title">{{title}}</h3>
        <div *ngIf="subTitle">{{subTitle}}</div>
    </div>
    <form [formGroup]="eventForm">
        <div class="modal-body">
            <tabset #eventFormTabs>
                <tab heading="Details">
                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex">
                            <label>Id</label>
                            <input class="form-control input-sm" formControlName="id" />
                        </div>
                        <div class="flex">
                            <label>Year</label>
                            <input class="form-control input-sm" type="number" formControlName="year" />
                        </div>
                        <div class="flex-2">
                            <label>Name</label>
                            <input class="form-control input-sm" formControlName="name" />
                        </div>
                    </div>
                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex">
                            <label>Begin Date</label>
                            <input class="form-control input-sm" type="date" formControlName="beginDate" [ngClass]="{ 'date-range-invalid': eventForm.errors?.['dateRangeError'] }"/>
                            <div *ngIf="eventForm.errors?.['dateRangeError']" class="text-danger">{{eventForm.errors?.['dateRangeError']}}</div>
                        </div>
                        <div class="flex">
                            <label>End Date</label>
                            <input class="form-control input-sm" type="date" formControlName="endDate" [ngClass]="{ 'date-range-invalid': eventForm.errors?.['dateRangeError'] }"/>
                        </div>
                        <div class="flex-2">
                            <label>Analyst</label>
                            <app-multi-user-select formControlName="analystUserIds" [activeUsersOnly]="true"></app-multi-user-select>
                        </div>
                    </div>
                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex">
                            <label>Event Type</label>
                            <select class="form-control input-sm" formControlName="eventTypeId">
                                <option *ngFor="let type of eventTypes$ | async" [ngValue]="type.Id">{{type.Name}}</option>
                            </select>
                        </div>
                        <div class="flex">
                            <label>Presenter Type</label>
                            <select class="form-control input-sm" formControlName="presenterTypeId">
                                <option *ngFor="let presenterType of eventPresenterTypes$ | async" [ngValue]="presenterType.Id">{{presenterType.Name}}</option>
                            </select>
                        </div>
                        <div class="flex">
                            <label>Sub Category</label>
                            <select class="form-control input-sm" formControlName="subCategory">
                                <option *ngFor="let subCat of subCategories$ | async" [value]="subCat">{{subCat}}</option>
                            </select>
                        </div>
                        <div class="flex">
                            <label>Conference</label>
                            <select class="form-control input-sm" formControlName="conferenceId">
                                <option *ngFor="let conf of eventConferences$ | async" [ngValue]="conf.Id">{{conf.Name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex">
                            <label>Event Coordinator</label>
                            <app-multi-user-select formControlName = "coordinatorUserIds" [activeUsersOnly]="true"></app-multi-user-select>
                        </div>
                        <div class="flex">
                            <label>Location / Time</label>
                            <input class="form-control input-sm" formControlName="location" />
                        </div>
                        <div class="flex">
                            <label>Public</label>
                            <select class="form-control input-sm" formControlName="isPublic">
                                <option [ngValue]="true">Yes</option>
                                <option [ngValue]="false">No</option>
                            </select>
                        </div>
                        <div class="flex">
                            <label>Cost</label>
                            <div class="input-group">
                                <span class="input-group-addon">$</span>
                                <input class="form-control input-sm" data-ng-min="0" data-ng-max="9999999999.99" formControlName="cost"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex">
                            <label>Last Invite Sent</label>
                            <input class="form-control input-sm" type="date" formControlName="lastInviteSent"/>
                        </div>
                        <div class="flex">
                            <label>Invitation Link</label>
                            <input class="form-control input-sm" formControlName="inviteLink"/>
                        </div>
                        <div class="flex">
                            <label>Max Attendance</label>
                            <input class="form-control input-sm" formControlName="maxAttendance"/>
                        </div>
                        <div class="flex">
                            <label>Broker</label>
                            <app-multi-user-select formControlName="brokerUserIds" [activeUsersOnly]="true"></app-multi-user-select>
                        </div>
                    </div>
                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex-1">
                            <label>Tiers/Sectors Invited</label>
                            <textarea class="form-control input-sm" formControlName="tiersSectorsInvited"></textarea>
                        </div>
                        <div class="flex">
                            <label>Dry Run Scheduled</label>
                            <input class="form-control input-sm" formControlName="dryRunScheduled"/>
                        </div>
                        <div class="flex">
                            <label>WebcastID</label>
                            <input class="form-control input-sm" formControlName="webcastId"/>
                        </div>
                        <div class="flex">
                            <label>IER Registration Link</label>
                            <input class="form-control input-sm" formControlName="ierRegistrationLink"/>
                        </div>
                    </div>
                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex-2">
                          <label>Notes</label>
                          <textarea class="form-control input-sm" formControlName="notes"></textarea>
                        </div>
                        <div class="flex">
                            <label>GIB Registration Link</label>
                            <input class="form-control input-sm" formControlName="gibRegistrationLink"/>
                        </div>
                        <div class="flex">
                            <label>MeetMax ID</label>
                            <input class="form-control input-sm" formControlName="meetMaxId"/>
                        </div>

                    </div>
                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex">
                          <label>Time Zone</label>
                          <app-time-zone-picker
                            formControlName="timeZoneId"
                            [multiple]="false"
                            [closeOnSelect]="true"
                            [showTagCountLabel]="false"
                            [showSelectClearAll]="false"
                          ></app-time-zone-picker>
                        </div>
                        <div class="flex">
                            <label>Canceled</label>
                            <select class="form-control input-sm" formControlName="isCancelled">
                                <option [ngValue]="false"></option>
                                <option [ngValue]="true">Yes</option>
                            </select>
                        </div>
                        <div class="flex">
                          <label>Agenda</label>
                          <input class="form-control input-sm" formControlName="agendaLink"/>
                        </div>
                        <div class="flex">
                            <label>Marketing Link</label>
                            <input class="form-control input-sm" formControlName="marketingLink"/>
                        </div>
                    </div>
                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex-2">
                            <label>Internal Notes</label>
                            <textarea class="form-control input-sm" style="height: 30px" formControlName="privateNotes"></textarea>
                        </div>
                        <div class="flex-1">
                          <label>Sector</label>
                          <app-instisel-sector-picker
                            [multiple]="false"
                            [closeOnSelect]="true"
                            [showSelectClearAll]="false"
                            [showNonParents]="false"
                            formControlName="sectorId">
                          </app-instisel-sector-picker>
                        </div>
                    </div>
                </tab>
                <tab heading="Companies">

                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex">
                            <label>Search</label>
                            <div class="flex-hbox flex-gap">
                                <input type="search"
                                       class="flex form-control input-sm"
                                       [formControl]="companySearch"
                                       placeholder="Search Company Name" />
                                <input type="search"
                                       class="form-control input-sm"
                                       style="width: 120px;"
                                       [formControl]="tickerSearch"
                                       placeholder="Search Ticker" />
                                <div class="checkbox" style="margin: 5px 0;">
                                    <label>
                                        <input type="checkbox" [formControl]="companySelected" />
                                        Only Show Selected
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="flex">
                            <br />
                            <div class="checkbox pull-right">
                                <label>
                                    <input type="checkbox" formControlName="allCompanies" />
                                    All Companies
                                </label>
                            </div>
                        </div>
                    </div>

                    <div style="position: relative; height: 400px;">
                        <ag-grid-angular
                                style="width: 100%; height: 100%;"
                                class="ag-theme-balham"
                                [gridOptions]="gridOptions"
                                [rowData]="rowData$ | async"
                                [components]="frameworkComponents"
                                [columnDefs]="columnDefs"
                                [defaultColDef]="defaultColDef"
                                (gridReady)="onGridReady($event)"
                                (cellValueChanged)="cellValueChanged($event)"
                        >
                        </ag-grid-angular>
                    </div>
                </tab>
                <tab heading="Invite Query" *ngIf="showInviteQuery && !isAdd ">
                    <div class="form-group">
                        <button type="button" class="btn btn-primary btn-xs" (click)="downloadContacts()">Download Contacts</button>
                        <span style="margin-left: 10px;">Matching {{ inviteQueryCount }} Contacts</span>
                    </div>
                    <app-query-groups-input formControlName="queryGroups"></app-query-groups-input>
                </tab>
                <tab heading="Invite Email" *ngIf="showInviteQuery && !isAdd ">
                    <div class="form-group">
                        <label>Subject:</label>
                        <input #subject type="text" class="form-control input-sm" formControlName="inviteEmailSubject" />
                    </div>
                    <div class="form-group">
                        <label>Body:</label>
                        <tabset>
                            <tab heading="Content">
                                <div class="text-right">
                                    <button
                                            type="button"
                                            class="btn btn-primary btn-xs"
                                            (click)="openImportUserEmailModal()"
                                            style="margin-left: 5px;"

                                    >
                                        <span class="fa fa-envelope-o"></span> Import Email From Outlook
                                    </button>
                                </div>
                                <div style="padding: 5px 0;">
                                    <app-html-editor #emailEditor formControlName="inviteEmailBody"></app-html-editor>
                                </div>
                            </tab>
                            <tab heading="Preview" (selectTab)="selectPreviewTab()">
                                <div class="preview" style="padding: 5px 0;">
                                    <iframe class="preview-html" [srcdoc]="previewHtml | trustHTML" #preview></iframe>
                                </div>
                            </tab>
                        </tabset>
                    </div>
                </tab>

              <tab heading="Email" *ngIf="showInviteQuery && !isAdd ">
                <div class="form-group flex-hbox flex-gap">
                  <div class="flex-1">
                    <label>Email Category</label>
                    <textarea class="form-control input-sm" style="height: 30px" formControlName="emailCategory"></textarea>
                  </div>
                  <div class="flex-2">
                    <label>Additional Notes</label>
                    <textarea class="form-control input-sm" style="height: 30px" formControlName="additionalNotes"></textarea>
                  </div>

                </div>
              </tab>

            </tabset>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button type="button" [disabled]="saveClicked" class="btn btn-primary btn-sm flex" (click)="save()">Save</button>
                <button type="button" class="btn btn-danger btn-sm flex" *ngIf="!isAdd" (click)="delete()">Delete</button>
                <button type="button" class="btn btn-default btn-sm flex" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>

