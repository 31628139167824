import {
    Component,
    forwardRef,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Observable, of} from "rxjs";
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl
} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {CKEditorComponent, CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {
    Autoformat,
    AutoImage,
    AutoLink,
    Autosave,
    Base64UploadAdapter,
    Bold,
    ClassicEditor,
    CloudServices,
    type EditorConfig,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontColorConfig,
    FontFamily,
    FontSize,
    FullPage,
    Heading,
    Highlight,
    HtmlComment,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    ShowBlocks,
    SourceEditing,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Underline,
    Undo
} from "ckeditor5";

@Component({
    selector: "app-ck-editor-5",
    standalone: true,
    imports: [CommonModule, CKEditorModule, ReactiveFormsModule],
    templateUrl: "./ck-editor-5.component.html",
    styles: [`
        .ck-editor__editable_inline, .ck-source-editing-area {
            min-height: 400px;
            max-width: 65vw; // TODO: Come back to this
            font-family: sans-serif, Arial, Verdana, 'Trebuchet MS';
            font-size: 13px;
        }

        .ck-editor__editable_inline {
            p {
                margin: 0;
            }
        }
    `],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CkEditor5Component),
            multi: true
        }
    ],
    encapsulation: ViewEncapsulation.None
})
export class CkEditor5Component implements OnInit, ControlValueAccessor {

    isDisabled = false;
    Editor = ClassicEditor;
    config: EditorConfig = {}; // CKEditor needs the DOM tree before calculating the configuration.

    onChange: (data: string) => void;

    html: UntypedFormControl = this.fb.control('');
    @ViewChild('editor', {static: true})
    editor: CKEditorComponent;

    campaignBodyPrefix = "<style>p{margin: 0;}</style><div style=\"font-family: sans-serif, Arial, Verdana, 'Trebuchet MS'; font-size: 13px;\">";
    campaignBodySuffix = "</div>";

    constructor(private fb: UntypedFormBuilder) {
    }

    ngOnInit(): void {
        this.config = this.getConfig();

        this.html.valueChanges
            .subscribe(data => {
                if (this.onChange) {
                    if (!data.startsWith(this.campaignBodyPrefix)) {
                        data = this.campaignBodyPrefix + data + this.campaignBodySuffix;
                    }
                    this.onChange(data);
                }
            });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: string): void {
        this.html.patchValue(obj);
    }

    setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }

    public insertText(str: string): void {
        this.editor?.editorInstance?.model?.change(writer => {
            const insertPosition = this.editor.editorInstance.model.document.selection.getFirstPosition();
            writer.insertText(str, insertPosition);
        });
    }

    public forceUpdate(): Observable<boolean> {
        this.html.patchValue(this.editor?.editorInstance?.getData());
        return of(true);
    }

    private getConfig(): EditorConfig {
        return {
            toolbar: {
                items: [
                    "undo",
                    "redo",
                    "|",
                    'bold',
                    'italic',
                    'underline',
                    'removeFormat',
                    '|',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'fontSize',
                    'fontFamily',
                    'heading',
                    "|",
                    'fontColor',
                    'fontBackgroundColor',
                    '|',
                    'link',
                    '|',
                    "insertImage",
                    'insertTable',
                    '|',
                    'sourceEditing',
                    'showBlocks',
                ],
                shouldNotGroupWhenFull: false
            },
            plugins: [
                Autoformat,
                AutoImage,
                AutoLink,
                Autosave,
                Base64UploadAdapter,
                Bold,
                CloudServices,
                Essentials,
                FontBackgroundColor,
                FontColor,
                FontFamily,
                FontSize,
                FullPage,
                Heading,
                Highlight,
                HtmlComment,
                HtmlEmbed,
                ImageBlock,
                ImageCaption,
                ImageInline,
                ImageInsertViaUrl,
                ImageResize,
                ImageStyle,
                ImageTextAlternative,
                ImageToolbar,
                ImageUpload,
                Italic,
                Link,
                LinkImage,
                List,
                ListProperties,
                Paragraph,
                PasteFromOffice,
                RemoveFormat,
                ShowBlocks,
                SourceEditing,
                Table,
                TableCaption,
                TableCellProperties,
                TableColumnResize,
                TableProperties,
                TableToolbar,
                TextTransformation,
                Underline,
                Undo
            ],
            fontFamily: {
                options: [
                    'Arial, Helvetica, sans-serif',
                    'Courier New, Courier, monospace',
                    'Georgia, serif',
                    'Lucida Sans Unicode, Lucida Grande, sans-serif',
                    'Tahoma, Geneva, sans-serif',
                    'Times New Roman, Times, serif',
                    'Trebuchet MS, Helvetica, sans-serif',
                    'Verdana, Geneva, sans-serif'
                ],
                supportAllValues: true
            },
            fontSize: {
                options: [9, 11, 13, 15, 'default', 17, 19, 21],
                supportAllValues: true
            },
            fontColor: this.getColorConfig(),
            fontBackgroundColor: this.getColorConfig(),
            link: {
                decorators: {
                    toggleDownloadable: {
                        mode: 'manual',
                        label: 'Downloadable',
                        attributes: {
                            download: 'file'
                        }
                    },
                    openInNewTab: {
                        mode: 'manual',
                        label: 'Open in a new tab',
                        defaultValue: true,			// This option will be selected by default.
                        attributes: {
                            target: '_blank',
                            rel: 'noopener noreferrer'
                        }
                    }
                },
                allowedProtocols: ['http', 'https', 'tel', 'mailto']
            },
            heading: {
                options: [
                    {
                        model: 'paragraph',
                        title: 'Paragraph',
                        class: 'ck-heading_paragraph'
                    },
                    {
                        model: 'heading1',
                        view: 'h1',
                        title: 'Heading 1',
                        class: 'ck-heading_heading1'
                    },
                    {
                        model: 'heading2',
                        view: 'h2',
                        title: 'Heading 2',
                        class: 'ck-heading_heading2'
                    },
                    {
                        model: 'heading3',
                        view: 'h3',
                        title: 'Heading 3',
                        class: 'ck-heading_heading3'
                    },
                    {
                        model: 'heading4',
                        view: 'h4',
                        title: 'Heading 4',
                        class: 'ck-heading_heading4'
                    },
                    {
                        model: 'heading5',
                        view: 'h5',
                        title: 'Heading 5',
                        class: 'ck-heading_heading5'
                    },
                    {
                        model: 'heading6',
                        view: 'h6',
                        title: 'Heading 6',
                        class: 'ck-heading_heading6'
                    }
                ]
            },
            htmlSupport: {
                allow: [
                    {
                        name: /.*/,
                        styles: true,
                        attributes: true,
                        classes: true
                    },
                    {
                        name: 'style',
                        styles: true,
                        attributes: true,
                        classes: true
                    },
                    {
                        name: 'head',
                        styles: true,
                        attributes: true,
                        classes: true
                    }
                ],
                disallow: [
                    {name: 'script'},
                ],
            },
            image: {
                toolbar: [
                    'toggleImageCaption',
                    'imageTextAlternative',
                    '|',
                    'imageStyle:inline',
                    'imageStyle:wrapText',
                    'imageStyle:breakText',
                    '|',
                    'resizeImage'
                ]
            },
            initialData: this.html.value,
            licenseKey: "GPL",
            list: {
                properties: {
                    styles: true,
                    startIndex: true,
                    reversed: true
                }
            },
            placeholder: '',
            table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
                tableProperties: this.getBaseTableAndCellProperties(),
                tableCellProperties: this.getBaseTableAndCellProperties(),
            }
        };
    }

    private getBaseTableAndCellProperties(): any {
        return {
            borderColors: this.getColors(),
            backgroundColors: this.getColors(),
            colorPicker: {
                format: "hex",
            },
            defaultProperties: {
                borderStyle: 'none',
                borderColor: '#D8D8D8',
                borderWidth: '0px',
            },
        };
    }

    private getColorConfig(): FontColorConfig {
        return {
            colors: this.getColors(),
            columns: 5,
            colorPicker: {
                format: "hex",
            },
        };
    }

    private getColors() {
        return [
            {color: '#FFFFFF', label: 'FFFFFF', hasBorder: true},
            {color: '#F2F2F2', label: 'F2F2F2'},
            {color: '#D8D8D8', label: 'D8D8D8'},
            {color: '#BFBFBF', label: 'BFBFBF'},
            {color: '#A5A5A5', label: 'A5A5A5'},
            {color: '#7F7F7F', label: '7F7F7F'},
            {color: '#595959', label: '595959'},
            {color: '#3F3F3F', label: '3F3F3F'},
            {color: '#262626', label: '262626'},
            {color: '#000000', label: '000000'},
            {color: '#CC0000', label: 'CC0000'},
            {color: '#FF0000', label: 'FF0000'},
            {color: '#FFCC00', label: 'FFCC00'},
            {color: '#FFFF00', label: 'FFFF00'},
            {color: '#99CC99', label: '99CC99'},
            {color: '#009966', label: '009966'},
            {color: '#0099FF', label: '0099FF'},
            {color: '#0066CC', label: '0066CC'},
            {color: '#003366', label: '003366'},
            {color: '#663399', label: '663399'},
        ];
    }

// setConfig(): void {
    //   this.editor.config = {
    //     enterMode: CKEDITOR.ENTER_BR,
    //     extraPlugins: "autogrow,basicstyles,font,uploadimage,colorbutton,tableresize",
    //     autoGrow_minHeight: 400,
    //     autoGrow_bottomSpace: 50,
    //     fontSize_sizes: "8/8pt;9/9pt;10/10pt;12/12pt;14/14pt;16/16pt;18/18pt;20/20pt;22/22pt;24/24pt;26/26pt;28/28pt;36/36pt;48/48pt;72/72pt",
    //     font_defaultLabel: "Arial",
    //     fontSize_defaultLabel: "10",
    //     resize_enabled: false,
    //     colorButton_colors: "FFFFFF,F2F2F2,D8D8D8,BFBFBF,A5A5A5,7F7F7F,595959,3F3F3F,262626,000000,CC0000,FF0000,FFCC00,FFFF00,99CC99,009966,0099FF,0066CC,003366,663399",
    //     colorButton_colorsPerRow: 10,
    //     disallowedContent: 'img{width,height}; script; meta; iframe; *[on*]',
    //     allowedContent: {
    //       $1: {
    //         elements: CKEDITOR.dtd,
    //         attributes: true,
    //         styles: true,
    //         classes: true
    //       }
    //     },
    //     uploadUrl: `${this.configService.getActiveApiUrl()}/upload`,
    //     imageUploadUrl: `${this.configService.getActiveApiUrl()}/upload`,
    //     toolbar: [
    //       {
    //         name: "history",
    //         items: ["Undo", "Redo"]
    //       },
    //       {
    //         name: "basics",
    //         items: ["Bold", "Italic", "Underline"]
    //       },
    //       {
    //         name: "lists",
    //         items: ["BulletedList", "NumberedList"]
    //       },
    //       {
    //         name: "font",
    //         items: ["Font", "FontSize"]
    //       },
    //       {
    //         name: "colors",
    //         items: ["TextColor", "BGColor"]
    //       },
    //       {
    //         name: "links",
    //         items: ["Link", "Unlink"]
    //       },
    //       {
    //         name: "insert",
    //         items: ["Image", "Table"]
    //       },
    //       {
    //         name: "source",
    //         items: ["Source"]
    //       },
    //       {
    //         name: "help",
    //         items: []
    //       }
    //     ],
    //     removeButtons: "",
    //     removePlugins: "elementspath,editorresize,showborders",
    //   };
    // }
}
