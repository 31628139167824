import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {
    FormControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {dateRenderer} from "../../Shared/ag-grid-cell-renderers";
import {
    EmailActivity,
    EmailCampaign,
    EmailMessage,
    EmailService,
    TestEmailMessage
} from "../../Shared/Services/email.service";
import {combineLatest, Observable, of} from "rxjs";
import * as moment from "moment";
import {map, startWith} from "rxjs/operators";
import {UserService} from "../../Shared/Services/user.service";
import {User} from "../../Shared/Models/user";
import {ReadershipModalService} from "../../Widget/Email/ReadershipModal/readership-modal-service";
import {Activity} from "../../../Models/Activity";
import {ToastrService} from "ngx-toastr";
import {BsModalService} from "ngx-bootstrap/modal";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import {ContactService} from "../../Shared/Services/contact.service";
import * as _ from "lodash";
import {HtmlEditorComponent} from "../../Widget/HtmlEditor/html-editor.component";
import {EmailSelectModalComponent} from "../../Widget/EmailSelectModal/email-select-modal.component";
import {EventService} from "../../Shared/Services/event.service";
import {ActivatedRoute} from "@angular/router";
import {EmailSignatureFormComponent} from "../ComposeEmail/email-signature-form.component";
import {CkEditor5Component} from "../../Widget/HtmlEditor/ck-editor-5.component";
import {TinyEditorComponent} from "../../Widget/HtmlEditor/tiny-editor.component";
import {FroalaEditorComponent} from "../../Widget/HtmlEditor/froala-editor.component";

@Component({
    selector: "app-email-compare-form",
    templateUrl: "./compose-email-compare.component.html",
    styleUrls: ["./compose-email-compare.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ComposeEmailCompareComponent extends BaseGridComponent<any> implements OnInit {

    columnDefs = [
        {
            field: 'CreatedAt',
            headerName: 'Date',
            sort: 'desc',
            width: 90,
            cellRenderer: dateRenderer
        },
        { field: 'Subject', headerName: 'Subject / Headline', flex: 2 },
        { field: 'SentBys',
            headerName: 'Sender',
            flex: 1,
            valueGetter: params => this.getSenderColumnValue(params.data),
            tooltipValueGetter: params => this.getSenderColumnValue(params.data),
        },
        {
            field: 'RecipientCount',
            headerName: 'Recs',
            headerTooltip: 'Recipients',
            width: 70
        },
    ];

    emailSearchForm: UntypedFormGroup = this.fb.group({
        searchTerm: this.fb.control(''),
        dateRange: this.fb.control({
            start: moment().add(-6, "months"),
            end: moment().endOf('day')
        }),
    });

    emailForm: UntypedFormGroup = this.fb.group({
        from: this.fb.control(''),
        onBehalfOf: this.fb.control(''),
        recipients: this.fb.control([], [Validators.required]),
        ccs: this.fb.control([]),
        ccBrokers: this.fb.control(false),
        bccs: this.fb.control([]),
        subject: this.fb.control('', [Validators.required, Validators.maxLength(1023)]),
        body: this.fb.control(''),
        ck5Body: this.fb.control(''),
        ck5BodyPaid: this.fb.control(''),
        tinyBody: this.fb.control(''),
        froalaBody: this.fb.control(''),
        includeSignature: this.fb.control(false),
        includeEmailTracking: this.fb.control(false),
        bccSelf: this.fb.control(false),
        logActivity: this.fb.control(true),
        activity: this.fb.control({
            CallDate: '',
            Category: '',
            SubCategory: '',
            MeetingType: null,
            Status: '',
            EventId: null,
            Duration: null,
            Side: '',
            StockPrice: null,
            StockCallCategory: '',
            Comment: '',
            Tickers: '',
            ParticipantUserIds: [],
        } as Activity),
    });

    ckEditor4Name = 'CK Editor 4';
    ckEditor5FreeName = 'CK Editor 5 Free';
    tinyMCEPaidName = 'TinyMCE Premium';
    froalaPaidName = 'Froala Premium';

    previewItems$: Observable<string[]> = of([
        this.ckEditor4Name,
        this.ckEditor5FreeName,
        this.tinyMCEPaidName,
        this.froalaPaidName,
    ])
    lastEditorTab = this.fb.control(this.ckEditor4Name);

    pinnedTopRowData$: Observable<EmailCampaign[]>;

    senders: User[] = [];
    onBehalfOfs: User[] = [];
    user: User = {} as User;
    allUsers: User[] = [];
    campaignId: number = null;
    draftId: number = null;

    @ViewChild('emailEditor', { static: true })
    emailEditor: HtmlEditorComponent;
    @ViewChild('emailEditor5', { static: true })
    emailEditor5Free: CkEditor5Component;
    @ViewChild('emailEditorTinyPaid', { static: true })
    emailEditorTinyPaid: TinyEditorComponent;
    @ViewChild('emailEditorFroala', { static: true })
    emailEditorFroalaPaid: FroalaEditorComponent;

    @ViewChild('mailMergeContainer', { static: true })
    mailMergeContainer;

    @ViewChild('preview', { static: true })
    preview: ElementRef<HTMLIFrameElement>;

    @ViewChild('subject')
    subjectInput: ElementRef<HTMLInputElement>;

    signature: string = "";
    previewIndex: number = 0;
    rawPreviewHtml: string = "";
    previewHtml: string = "";
    previewContactName: string = "";

    loading: boolean = false;
    sendingEmail: boolean = false;
    sendingTestEmail: boolean = false;
    showActivityForm: boolean = false;
    commentPlaceholder: string = "";

    includeDoNotContactContacts: boolean = true;

    constructor(private fb: UntypedFormBuilder,
                private emailService: EmailService,
                private userService: UserService,
                private eventService: EventService,
                private contactService: ContactService,
                private readershipModalService: ReadershipModalService,
                private toastrService: ToastrService,
                private modalService: BsModalService,
                private route: ActivatedRoute,
    ) {
        super();
    }

    ngOnInit(): void {
        this.loading = true;

        let user$ = this.userService.getCurrentUser();

        let allUsers$ = this.userService.getUsers();

        combineLatest([user$, allUsers$])
            .subscribe(([user, allUsers]) => {

                this.user = user;
                this.allUsers = allUsers;

                this.userService.getSenderPermissions(user.Id)
                    .subscribe(sendAsUsers => {
                        this.senders = sendAsUsers.sort((a,b) => a.LastName < b.LastName ? 1 : -1);
                    });

                this.userService.getSendOnBehalfOfPermissions(user.Id)
                    .subscribe(sendOnBehalfOfUsers => {
                        this.onBehalfOfs = sendOnBehalfOfUsers.sort((a,b) => a.LastName < b.LastName ? 1 : -1);
                    });

                this.includeDoNotContactContacts = this.userService.canViewContactEmailWithDoNotContact(user);

                this.emailForm.markAsPristine();
                this.new();

                const eventId = +this.route.snapshot.paramMap.get('eventId');

                if (eventId ?? false) {
                    if (this.route.snapshot.paramMap.get('includeRecipients') === 'true'){
                        this.eventService.getEventQueryRecipients(eventId)
                            .subscribe(contactIds => {
                                this.emailForm.patchValue({
                                    recipients: contactIds,
                                })
                            });
                    }
                    this.eventService.getEventInviteTemplate(eventId)
                        .subscribe(t => {
                            this.emailForm.patchValue({
                                subject: t.Subject,
                            });
                            this.getBodyFromActiveEditor().patchValue(t.Body);
                        });
                }

                this.loading = false;
            });

        this.emailForm.get('from').valueChanges.pipe(
            startWith(this.emailForm.get('from').value)
        )
            .subscribe(fromUserId => {
                this.emailService.getSignature(fromUserId)
                    .subscribe(signature => {
                        this.signature = signature.SignatureHtml;
                    }, error => {
                        this.signature = "";
                    });
            });

        this.emailForm.get('onBehalfOf').valueChanges
            .subscribe(onBehalfUserId => {
                if (onBehalfUserId !== "") {
                    this.emailForm.get('from').disable();
                    this.emailForm.get('from').patchValue(this.user.Id);
                } else {
                    this.emailForm.get('from').enable();
                }
            });

        this.emailSearchForm.valueChanges.pipe(
            startWith(this.emailSearchForm.value)
        )
            .subscribe(formValues => {
                this.pinnedTopRowData$ = this.emailService.getMyDraftEmailCampaigns().pipe(
                    map(drafts => drafts.map(draft => {
                        draft.CreatedAt = null;
                        return draft;
                    }))
                );

                this.rowData$ = this.emailService.getMyEmailCampaigns(formValues.dateRange.start, formValues.dateRange.end, formValues.searchTerm);
            });

        this.emailForm.get('logActivity').valueChanges
            .pipe(
                startWith(this.emailForm.get('logActivity').value)
            )
            .subscribe(logActivity => {
                this.showActivityForm = logActivity;
                if (this.showActivityForm) {
                    this.emailForm.get('activity').enable();
                } else {
                    this.emailForm.get('activity').disable();
                }
            });

        this.emailForm.get('subject').valueChanges
            .subscribe(subject => {
                this.commentPlaceholder = subject;
            })

        this.lastEditorTab.valueChanges
            .subscribe(tab => {
                this.selectPreviewTab();
            })
    }

    loadHistory(): void {
        this.emailSearchForm.patchValue({});
    }

    initializeForm(subject: string, body: string, recipients: number[] = []): void {
        this.mailMergeContainer.nativeElement.scrollTop = 0;

        this.emailForm.patchValue({
            from: this.user.Id,
            onBehalfOf: '',
            recipients: recipients || [],
            ccs: [],
            ccBrokers: false,
            bccs: [],
            subject: subject,
            includeSignature: true,
            includeEmailTracking: true,
            bccSelf: false,
            logActivity: true,
            activity: {
                CallDate: '',
                Category: '',
                SubCategory: '',
                MeetingType: null,
                Status: '',
                EventId: null,
                Duration: null,
                Side: '',
                Price: null,
                StockCallCategory: '',
                Comment: '',
                Tickers: '',
                ParticipantUserIds: [this.user.Id]
            }
        });
        this.getBodyFromActiveEditor().patchValue(body);

        this.emailForm.enable();
    }

    new() {
        if (this.emailForm.dirty && this.emailForm.enabled) {
            let modalRef = this.modalService.show(ConfirmModalComponent, { initialState: { message: 'You will lose all data in the form.  Do you want to continue?' } });
            modalRef.content.action.subscribe(_ => {
                this.campaignId = null;
                this.draftId = null;
                this.initializeForm('', '');
            });
        } else {
            this.campaignId = null;
            this.draftId = null;
            this.initializeForm('', '');
        }
    }

    newFromExisting(campaignId: number) {
        this.campaignId = null;
        this.draftId = null;

        this.initializeForm(this.emailForm.get('subject').value,
            this.getBodyFromActiveEditor().value,
            this.emailForm.get('recipients').value);
    }

    details(campaignId: number) {
        this.readershipModalService.openReadershipModal(campaignId, '');
    }

    getTestMessage(): TestEmailMessage {
        let body = this.getBodyFromActiveEditor().value;
        if (this.emailForm.get('includeSignature').value) {
            body += this.signature;
        }
        return {
            From: this.emailForm.get('from').value,
            OnBehalfOf: this.emailForm.get('onBehalfOf').value,
            To: this.user.Email,
            Subject: '[TEST] - ' + this.emailForm.get('subject').value,
            Body: body as string,
        };
    }

    getMessage(): EmailMessage {

        const ccs = this.emailForm.get('ccs').value
            .map(userId => this.allUsers.find(u => u.Id === userId))
            .filter(user => user)
            .map(user => user.Email);
        const bccs = this.emailForm.get('bccSelf').value ? [this.user.Email] : [];
        let body = this.getBodyFromActiveEditor().value;
        if (this.emailForm.get('includeSignature').value) {
            body += this.signature;
        }
        return {
            From: this.emailForm.get('from').value,
            OnBehalfOf: this.emailForm.get('onBehalfOf').value,
            To: this.emailForm.get('recipients').value,
            Cc: ccs,
            CcBrokers: this.emailForm.get('ccBrokers').value,
            Bcc: bccs,
            Subject: this.emailForm.get('subject').value,
            Body: body,
            IsTracked: this.emailForm.get('includeEmailTracking').value,
            DraftId: this.draftId || 0,
        };
    }

    getActivity(): EmailActivity {
        if (this.showActivityForm) {
            let activityValue = this.emailForm.get('activity').value;
            return {
                CallDate: moment(),
                Category: activityValue.Category,
                SubCategory: activityValue.SubCategory,
                Type: activityValue.MeetingType,
                Status: activityValue.Status,
                EventId: activityValue.EventId,
                Duration: activityValue.Duration,
                Side: activityValue.Side,
                Price: activityValue.Price,
                StockCallCategory: activityValue.StockCallCategory,
                Comment: activityValue.Comment ? activityValue.Comment : this.emailForm.get('subject').value,
                Tickers: activityValue.Tickers,
                ParticipantUserIds: activityValue.ParticipantUserIds,
                ContactedBy: this.emailForm.get('from').value,
            };
        }

        return {} as EmailActivity;
    }

    send() {
        if (this.emailForm.invalid) return;

        this.sendingEmail = true;

        let forceUpdate$ = this.getForceUpdateObservable();

        forceUpdate$.subscribe(() => {
            this.emailService.sendEmail(this.getMessage(), this.getActivity())
                .subscribe(() => {
                    this.draftId = null;
                    this.toastrService.success(`Emails are being sent out!`);
                    this.initializeForm('', '');
                    this.loadHistory();
                    this.sendingEmail = false;
                });
        });
    }

    sendTest() {
        this.sendingTestEmail = true;

        let forceUpdate$ = this.getForceUpdateObservable();

        forceUpdate$.subscribe(() => {
            this.emailService.sendTestEmail(this.getTestMessage())
                .subscribe(_ => {
                    this.toastrService.success(`Test email has been sent to ${this.user.Email}!`);
                    this.sendingTestEmail = false;
                });
        });
    }

    saveDraft() {
        let forceUpdate$ = this.getForceUpdateObservable();

        forceUpdate$.subscribe(() => {
            let message = {
                subject: this.emailForm.value.subject,
                body: this.emailForm.value.body,
            };
            this.emailService.saveDraft(this.draftId, message)
                .subscribe(ret => {
                    this.draftId = ret?.Id || this.draftId;
                    this.toastrService.success(`Draft Saved`);
                    this.loadHistory();
                });
        });
    }

    deleteDraft() {

        let modalRef = this.modalService.show(ConfirmModalComponent, { initialState: { message: 'Do you want to delete?' } });

        modalRef.content.action.subscribe(_ => {
            this.emailService.deleteDraft(this.draftId)
                .subscribe(() => {
                    this.draftId = null;
                    this.toastrService.success(`Draft Deleted`);
                    this.loadHistory();
                    this.initializeForm('', '');
                });
        });
    }

    rowClicked($event: any) {

        this.mailMergeContainer.nativeElement.scrollTop = 0;

        if (!$event.data.CreatedAt) {
            this.campaignId = null;
            this.draftId = $event.data.Id;
            this.emailService.getEmailCampaignDetails(this.draftId).subscribe(campaign => {
                this.initializeForm(campaign.Subject, campaign.Html);
            });
            return;
        }

        this.draftId = null;
        this.campaignId = $event.data.Id;
        this.initializeForm('', '');
        this.emailForm.disable();

        this.emailService.getEmailCampaignDetails($event.data.Id)
            .subscribe(campaign => {
                this.emailForm.patchValue({
                    from: campaign.SentBys[0].Id,
                    onBehalfOf: campaign.SendOnBehalfOfs[0]?.Id || '',
                    subject: campaign.Subject,
                });
                this.getBodyFromActiveEditor().patchValue(campaign.Html);
            });
    }

    openSignatureForm() {
        let modalRef = this.modalService.show(EmailSignatureFormComponent, {
            backdrop: 'static',
            keyboard: false,
            animated: false,
            class: 'modal-lg'
        });

        modalRef.content.action.subscribe(_ => {
            if(this.user.Id === this.emailForm.get('from').value) {
                this.emailService.getSignature(this.emailForm.get('from').value)
                    .subscribe(signature => {
                        this.signature = signature.SignatureHtml;
                    });
            }
        });
    }

    insertNamePlaceholder() {
        this.insertTextIntoActiveEditor("{{corresp_name}}");
    }

    openImportUserEmailModal() {
        let modalRef = this.modalService.show(
            EmailSelectModalComponent, {
                animated: false,
                keyboard: false,
                backdrop: 'static',
                class: 'modal-xl'
            });

        modalRef.content.selectedEmail$.subscribe(selectedEmail => {
            this.mailMergeContainer.nativeElement.scrollTop = 0;
            this.draftId = null;
            this.campaignId = null;

            this.initializeForm(
                this.emailForm.get('subject').value,
                selectedEmail.Body,
                this.emailForm.get('recipients').value,
                );
        });
    }

    changeTab($event) {
        this.lastEditorTab.patchValue($event.heading);
    }

    selectPreviewTab() {
        let forceUpdate$ = this.getForceUpdateObservable();

        forceUpdate$.subscribe(() => {
            this.previewIndex = 0;

            this.rawPreviewHtml = this.getBodyFromActiveEditor().value;

            if (this.emailForm.get('includeSignature').value) {
                this.rawPreviewHtml += this.signature;
            }

            this.previewHtml = this.rawPreviewHtml;

            this.substitutePreviewHtml();
        });
    }

    changePreview(changeBy: number): void {
        if (!(this.previewIndex + changeBy > this.emailForm.get('recipients').value.length - 1) && !(this.previewIndex + changeBy < 0)) {
            this.previewIndex = this.previewIndex + changeBy;

            this.substitutePreviewHtml();
        }
    }

    substitutePreviewHtml(): void {
        this.previewContactName = "";
        if (this.previewIndex < this.emailForm.get('recipients').value.length) {
            let contactId = this.emailForm.get('recipients').value[this.previewIndex];
            this.contactService.getContactById(contactId)
                .subscribe(contact => {
                    this.previewContactName = `${contact.FirstName} ${contact.LastName}`;
                    if (contact.Account?.Name) {
                        this.previewContactName += ` (${contact.Account.Name})`;
                    }
                    if (contact.Email) {
                        this.previewContactName += ` ${contact.Email}`;
                    }
                    this.previewHtml = _.replace(this.rawPreviewHtml, /\{\{\s?corresp_name\s?\}\}/g, contact.Alias || contact.FirstName || "");
                });
        }
        setTimeout(() => {
            // add target _blank to all links so they don't open in the preview window
            this.preview?.nativeElement.contentDocument.querySelectorAll("a").forEach(a => a.setAttribute("target", "_blank"));
        }, 250);
    }

    setIframeHeight(): void {
        this.preview.nativeElement.height = this.preview.nativeElement.contentWindow.document.body.scrollHeight + 60 + "px";
    }

    insertNamePlaceholderSubject() {
        let currentSubject = this.emailForm.get("subject").value;
        let start = this.subjectInput.nativeElement.selectionStart
        let end = this.subjectInput.nativeElement.selectionEnd
        let newSubject = currentSubject.slice(0, start) + "{{corresp_name}}" + currentSubject.slice(end);

        this.emailForm.get("subject").patchValue(newSubject);
        this.subjectInput.nativeElement.focus();
    }

    getSenderColumnValue(campaign: EmailCampaign): string {
        let sentAs = campaign.SentBys ? campaign.SentBys.map(p => p.LastName).sort().join(', ') : '';
        let sentOnBehalfOf = campaign.SendOnBehalfOfs ? campaign.SendOnBehalfOfs.map(p => p.LastName).sort().join(', ') : '';

        return sentOnBehalfOf ? `${sentAs} OBO ${sentOnBehalfOf}` : sentAs;
    }

    private getBodyFromActiveEditor(): FormControl {
        switch (this.lastEditorTab.value) {
            case this.ckEditor4Name:
                return this.emailForm.get('body') as FormControl;
            case this.ckEditor5FreeName:
                return this.emailForm.get('ck5Body') as FormControl;
            case this.tinyMCEPaidName:
                return this.emailForm.get('tinyBody') as FormControl;
            case this.froalaPaidName:
                return this.emailForm.get('froalaBody') as FormControl;
            default:
                throw new Error('Invalid editor tab');
        }
    }

    private getForceUpdateObservable() {
        switch (this.lastEditorTab.value) {
            case this.ckEditor4Name:
                return this.emailEditor.forceUpdate();
            case this.ckEditor5FreeName:
                return this.emailEditor5Free.forceUpdate();
            case this.tinyMCEPaidName:
                return this.emailEditorTinyPaid.forceUpdate();
            case this.froalaPaidName:
                return this.emailEditorFroalaPaid.forceUpdate();
            default:
                throw new Error('Invalid editor tab');
        }
    }

    private insertTextIntoActiveEditor(text: string) {
        switch (this.lastEditorTab.value) {
            case this.ckEditor4Name:
                return this.emailEditor.insertText(text);
            case this.ckEditor5FreeName:
                return this.emailEditor5Free.insertText(text);
            case this.tinyMCEPaidName:
                return this.emailEditorTinyPaid.insertText(text);
            case this.froalaPaidName:
                return this.emailEditorFroalaPaid.insertText(text);
            default:
                throw new Error('Invalid editor tab');
        }
    }
}
