import {
    Component,
    forwardRef,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Observable, of} from "rxjs";
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    UntypedFormBuilder,
    UntypedFormControl
} from "@angular/forms";
import {ConfigService} from "../../Shared/Services/config.service";

declare let FroalaEditor: any;
@Component({
    selector: "app-froala-editor",
    templateUrl: "./froala-editor.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FroalaEditorComponent),
            multi: true
        }
    ]
})
export class FroalaEditorComponent implements OnInit, ControlValueAccessor {

    isDisabled = false;

    onChange: (data: string) => void;

    html: UntypedFormControl = this.fb.control('');

    campaignBodyPrefix = "<style>p {margin: 0;} a:link{ text-decoration: none;}</style><div style=\"font-family: sans-serif, Arial, Verdana, 'Trebuchet MS'; font-size: 13px;\">";
    campaignBodySuffix = "</div>";

    // @ViewChild('editor', { static: true})
    editorComponent: any;

    constructor(private fb: UntypedFormBuilder, private configService: ConfigService) {}

    ngOnInit(): void {
        this.editorComponent = new FroalaEditor('#example', {
            htmlUntouched: true,
            fullPage: true,
            htmlRemoveTags: ['script'],
            toolbarButtons: [
                ['undo', 'redo'],
                ['bold', 'italic', 'underline', 'clearFormatting'],
                ['formatOL', 'formatUL'],
                ['fontFamily', 'fontSize', 'textColor', 'backgroundColor'],
                ['insertLink', 'insertImage', 'insertTable'],
                ['html'],
            ],
            fontFamily: {
                'Arial, Helvetica, sans-serif': 'Arial',
                'Courier New, Courier, monospace': 'Courier New',
                'Georgia, serif': 'Georgia',
                'Lucida Sans Unicode, Lucida Grande, sans-serif': 'Lucida Sans Unicode',
                'Tahoma, Geneva, sans-serif': 'Tahoma',
                'Times New Roman, Times, serif': 'Times New Roman',
                'Trebuchet MS, Helvetica, sans-serif': 'Trebuchet MS',
                'Verdana, Geneva, sans-serif': 'Verdana',
            },
            imageEditButtons: ['imageReplace', 'imageSize', 'imageAlign', 'imageDisplay', '-', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', 'imageAlt', 'imageRemove'],
            imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
            iframeStyleFiles: [
                'https://cdn.jsdelivr.net/npm/froala-editor@4.4.0/css/froala_editor.pkgd.min.css',
                'https://cdn.jsdelivr.net/npm/froala-editor@4.4.0/css/plugins/table.min.css',
                'https://cdn.jsdelivr.net/npm/froala-editor@4.4.0/css/plugins/colors.min.css'
            ],
            toolbarSticky: false,
            wordCounterCount: false,
            charCounterCount: false,
            heightMin: 400,
            placeholderText: '',
            events: {
                "image.beforeUpload": function(files) {
                    var editor = this;
                    if (files.length) {

                        // Create a File Reader.
                        var reader = new FileReader();
                        reader.onload = function(e) {
                            var result = e.target.result;
                            editor.image.insert(result, null, null, editor.image.get());
                        };
                        // Read image as base64.
                        reader.readAsDataURL(files[0]);
                    }

                    editor.popups.hideAll();
                    // Stop default upload chain.
                    return false;
                },
                'image.beforePasteUpload': function (files) {
                    var editor = this;
                    if (files.length) {

                        // Create a File Reader.
                        var reader = new FileReader();
                        // Read image as base64.
                        reader.readAsDataURL(files[0]);
                    }

                    editor.popups.hideAll();
                    // Stop default upload chain.
                    return false;
                }
            }
        });

        this.html.valueChanges
            .subscribe(data => {
                if (this.onChange) {
                    this.editorComponent.html.set(data);
                    if (!data.startsWith(this.campaignBodyPrefix)) {
                        data = this.campaignBodyPrefix + data + this.campaignBodySuffix;
                    }
                    this.onChange(data);
                }
            });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: string): void {
        this.html.patchValue(obj);
    }

    setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }

    public insertText(str: string): void {
        this.editorComponent.html.insert(str);
    }

    public forceUpdate(): Observable<boolean> {
         this.html.patchValue(this.editorComponent.html.get());
         return of(true);
    }
}
